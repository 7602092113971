import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-scroll";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <div className=" bg-dark-blue min-h-[56vh]  w-full font-inter overflow-x-hidden">
      <div className="w-full sm:h-[20vh] h-[10vh] flex flex-row items-center px-2">
        <div className="sm:flex sm:flex-col items-center text-white hidden md:block">
          <img
            className="sm:h-20 h-16 w-20 mx-8 transition-shadow duration-300 hover:shadow-lg rounded-full"
            src="hirersLogo.png"
            alt="Logo"
          />
        </div>
        <div>
          <p className="text-white sm:text-3xl text-xl font-bold tracking-wide uppercase">
            HIRERS
          </p>
          <div className="flex flex-row space-x-1">
            {/* Social Links */}
            <a
              href="https://www.linkedin.com/company/hirers-co-in/?viewAsMember=true"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="w-12 h-10 rounded-full hidden md:block  transform hover:scale-125 transition-shadow duration-300 hover:shadow-lg"
                src="linkedinLogo.png"
                alt="LinkedIn"
              />
            </a>
            <a
              href="https://www.facebook.com/hirers.co.in/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="w-12 h-10 rounded-full hidden md:block transform hover:scale-125 transition-shadow duration-300 hover:shadow-lg"
                src="facebook_logo.png"
                alt="Facebook"
              />
            </a>
            <a
              href="https://www.instagram.com/hirers.co.in/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="w-12 h-10 rounded-full hidden md:block  transform hover:scale-125 transition-shadow duration-300 hover:shadow-lg"
                src="instagramLogo.png"
                alt="Instagram"
              />
            </a>
          </div>
        </div>

        <div className="flex justify-end w-full pr-6">
          <p className="text-white md:text-2xl text-sm semibold text-right">
            "Aapke Career ka Perfect Partner!"
          </p>
        </div>
      </div>

      <hr className="border-t border-white" />

      <div className="relative flex flex-wrap lg:flex-row flex-col text-white w-full mb-4">
        <div className="relative flex flex-row lg:w-[50%] justify-evenly ">
          <div className=" m-3 font-semibold">
            <p className="hover:text-yellow-100 transition-colors duration-200 cursor-pointer mb-3 sm:text-lg text-sm">
              <Link to="home" smooth={true} duration={800}>
                Home
              </Link>
            </p>
            <p className="hover:text-yellow-100 transition-colors duration-200 cursor-pointer mb-3 sm:text-lg text-sm">
              <Link to="about-us" smooth={true} duration={800}>
                About Us
              </Link>
            </p>
            <p
              onClick={() => navigate("/Contact")}
              className="hover:text-yellow-100 transition-colors duration-200 cursor-pointer mb-3  sm:text-lg text-sm"
            >
              Contact Us
            </p>
            <p
              className="hover:text-yellow-100 transition-colors duration-200 cursor-pointer mb-3 sm:text-lg text-sm"
              onClick={() => navigate("/refund_policy")}
            >
              Refund Policy
            </p>
            <p
              className="hover:text-yellow-100 transition-colors duration-200 cursor-pointer mb-3 sm:text-lg text-sm"
              onClick={() => navigate("/terms_conditions")}
            >
              Terms and Conditions
            </p>
            <p
              className=" hover:text-yellow-100 transition-colors duration-200 cursor-pointer mb-3 sm:text-lg text-sm"
              onClick={() => navigate("/privacy_policy")}
            >
              Privacy Policy
            </p>

            <p
              className="hover:text-yellow-100 transition-colors duration-200 cursor-pointer mb-3 sm:text-lg text-sm"
              onClick={() => {
                navigate("/pricing_page", {
                  state: { isCheckout: false },
                });
               
              }}
            >
              Pricing Page
            </p>
          </div>
          <div className="m-3 font-semibold">
            <p
              className="hover:text-yellow-100 transition-colors duration-200 w-20 cursor-pointer mb-3 sm:text-lg text-sm"
              onClick={() => navigate("/Desktop-3")}
            >
              Post Job
            </p>
            <p
              className="hover:text-yellow-100 transition-colors duration-200  cursor-pointer mb-3  sm:text-lg text-sm"
              onClick={() => navigate("/Frame-11")}
            >
              {" "}
              SearchJob
            </p>
          </div>
          <div className="mt-3">
            <p className="sm:text-lg text-sm font-semibold">For Queries-:</p>
            <p className="  font-semibold sm:text-lg text-sm">
              info@hirers.co.in
            </p>
          </div>
        </div>

        <div className="relative flex flex-row lg:w-[50%] xs:justify-evenly justify-start ">
          <div className="m-3">
            <p className="sm:text-lg text-sm font-semibold">Mobile-:</p>
            <p className="hover:text-yellow-300 sm:text-lg text-sm font-semibold transition-colors duration-200">
              +917424947309
            </p>
            <p className="hover:text-yellow-300 sm:text-lg text-sm font-semibold transition-colors duration-200">
              +919306238429
            </p>
            <p className="hover:text-yellow-300 sm:text-lg text-sm  font-semibold transition-colors duration-200">
              +919545653199
            </p>
          </div>

          <div className=" mt-2 ml-4 -mr-10 sm:m-2 font-semibold sm:text-lg text-sm">
            <p className="">Address-:</p>
            <p>7/302 Laxmi Nagar</p>
            <p>New Delhi 110092</p>
          </div>
        </div>
      </div>

      <div className="flex items-end justify-between w-full mt-6 px-4 mb-2">
        <div></div>
        {/* Mobile View "Follow us On" Section */}
        <div className="sm:hidden">
          <p className="text-white text-sm font-semibold">Follow us On-:</p>
          <div className="flex  space-x-2 mt-2">
            <a
              href="https://www.instagram.com/hirers.co.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-yellow-300 underline text-sm"
            >
              <img
                className="w-8 h-8 rounded-full "
                src="instagramLogo.png"
                alt="Instagram"
              />
            </a>
            <a
              href="https://www.facebook.com/hirers.co.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-yellow-300 underline text-sm"
            >
              <img
                className="w-8 h-8 rounded-full "
                src="facebook_logo.png"
                alt="Facebook"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/hirers-co-in/?viewAsMember=true"
              target="_blank"
              rel="noopener noreferrer"
              className="text-yellow-300 underline text-sm"
            >
              <img
                className="w-8 h-8 rounded-full "
                src="linkedinLogo.png"
                alt="LinkedIn"
              />
            </a>
          </div>
        </div>
        {/* Footer Credits */}
        <p className="text-white text-sm font-semibold ml-4">
          © 2023 by Hirers - All Rights Reserved
        </p>
      </div>
    </div>
  );
};

export default Footer;
