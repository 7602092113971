import React, { useEffect, useState } from "react";
import axios from "axios";
// import NotFound from "../UnknowRoute/NotFound";
import HashLoader from "react-spinners/HashLoader";
// import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
// import { jobsData } from "../../data/jobsData";
import { FaArrowLeftLong } from "react-icons/fa6";
import Navbar from "../Sections/Navbar-3&5/navbar1";

// Example Navbar1 component

const CandidateCard = ({
  id,
  companyName,
  companyAddress,
  jobTitle,
  jobLocation,
  educationQualification,
  experienceFresher,
  interviewLocation,
  nameAndDesignation,
  mobileNumber,
  email,
  workType,
  languagesKnown,
  salary,
  workingHours,
  jobDescription,
  appliedDate,
  status,
  updateJobStatus,
  profile, // Pass updateJobStatus as a prop
}) => {
  const navigate = useNavigate();
  const handleCheckResponses = (documentId, jobTitle) => {
    navigate(`/Section-3/${documentId}`, { state: jobTitle });
  };
  const location = useLocation();
  const jobId = location.state.jobId;

  const handleWaiting = () => {
    Swal.fire({
      title: "Under Progress",
      text: "Admin is verifying your details on progress",
      icon: "info",
      confirmButtonText: "Got it!", // Add a custom button
      confirmButtonColor: "#3085d6", // Change the button color
    });
  };

  return (
    <div className="sm:p-4 py-4 px-8  rounded-2xl shadow-md mb-4 bg-[#DFDCF7]">
      <div className="relative flex flex-col sm:flex-row gap-y-6 items-start justify-between ">
        <div className="lg:w-[8%] sm:w-[10%] w-full flex justify-center sm:justify-start mr-4">
          <img
            src={profile ? profile : "/profile.jpg"} // Adjusted fallback path
            alt="Profile"
            className=" rounded-full sm:mb-4  w-16 h-16 sm:h-14 sm:w-14 md:h-16 md:w-16 "
          />

        </div>

        <div className="flex-grow  lg:w-[70%] smd:w-[65%] sm:w-[60%]  mx-auto  xsm:w-[90%] w-full ">
          <div className="grid grid-cols-1 xsm:grid-cols-2 lg:grid-cols-3 gap-y-4 xl:text-lg text-base ">
            <div className="space-y-2  ">
              <p>
                {companyName},{companyAddress}
              </p>
              <p>{jobTitle}</p>
              <p>{jobLocation}</p>
              <p>{educationQualification}</p>
              <p>{experienceFresher}</p>
              <p>{interviewLocation}</p>
            </div>
            <div className="space-y-2  ">
              <p>{nameAndDesignation}</p>
              <p>{mobileNumber}</p>
              <p>{workType}</p>
              <p>{languagesKnown}</p>
              <p>{salary}</p>
            </div>
            <div className="space-y-2  xs:w-max w-full">
              <p className="">{email}</p>
              <p>{workingHours}</p>
              <p>{jobDescription}</p>
            </div>
          </div>
        </div>
        {/* <div className="absolute right-2 md:top-1 top-52 flex flex-col items-end space-y-2 mt-4 md:mt-0 md:ml-4"></div> */}
        {/* <div className="absolute right-2 md:top-1 top-52 flex flex-col items-end space-y-2 mt-4 md:mt-0 md:ml-4">
        {status !== "accepted" && (
          <button
            className="bg-white hover:bg-green-300 text-green-700 font-bold py-1 px-4 rounded-2xl h-9 w-36 md:py-1 md:px-4 md:h-9 md:w-36 sm:py-0.5 sm:px-2 sm:h-8 sm:w-28"
            onClick={() => updateJobStatus(id, "accepted")}
          >
            Accept
          </button>
        )}
        {status === "accepted" && (
          <button className="bg-green-300 text-green-700 font-bold py-1 px-4 rounded-2xl h-9 w-36 md:py-1 md:px-4 md:h-9 md:w-36 sm:py-0.5 sm:px-2 sm:h-8 sm:w-28 cursor-not-allowed">
            Accepted
          </button>
        )}
        {status !== "rejected" && (
          <button
            className="bg-white hover:bg-red-300 text-red-700 font-bold py-1 px-4 rounded-2xl h-9 w-36 md:py-1 md:px-4 md:h-9 md:w-36 sm:py-0.5 sm:px-2 sm:h-8 sm:w-28"
            onClick={() => updateJobStatus(id, "rejected")}
          >
            Reject
          </button>
        )}
        {status === "rejected" && (
          <button className="bg-red-300  text-red-700 font-bold py-1 px-4 rounded-2xl h-9 w-36 md:py-1 md:px-4 md:h-9 md:w-36 sm:py-0.5 sm:px-2 sm:h-8 sm:w-28 cursor-not-allowed">
            Rejected
          </button>
        )}
        <button
          className={`bg-white ${
            status === "accepted"
              ? "text-green-700 hover:bg-green-300"
              : status === "rejected"
              ? "text-red-700 hover:bg-red-300"
              : "text-yellow-700 hover:bg-yellow-300"
          } font-bold py-2 px-4 rounded-2xl h-9 w-36 md:py-2 md:px-4 md:h-9 md:w-36 sm:py-0.5 sm:px-2 sm:h-8 sm:w-28`}
          disabled={status === "accepted" || status === "rejected"}
        >
          Edit
        </button>
      </div> */}
        <div className="lg:w-[15%] smd:w-[20%] sm:w-[25%] w-full  flex flex-col items-center gap-y-6">

          <button
            className={`flex w-max justify-center py-2  px-3 bg-white rounded-2xl transition-all duration-300 hover:scale-105 text-base  ${status === "rejected"
              ? "cursor-not-allowed opacity-50"
              : "cursor-pointer hover:bg-gray-200 hover:shadow-xl transition-shadow duration-200"
              }`}
            onClick={() => {
              if (status === "accepted") {
                handleCheckResponses(jobId, jobTitle);
              } else if (status === "waiting") {
                handleWaiting();
              }
            }}
            disabled={status === "rejected"} // Disable button if status is rejected
          >
            Check Response
          </button>

          <p className="text-gray-500 text-sm text-right mt-4">{appliedDate}</p>

        </div>
       

      </div>

    </div>
  );
};

const Section8 = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const uid = localStorage.getItem("token");
  //   const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [allowRender , setAllowRender] =  useState(false);
  const [job, setJob] = useState(null);
  //   const jobTitle = location.state;
  //   const jobId = location.jobId;
  const { jobId, jobTitle } = location.state || {}; // Access state safely

  //   const navigate = useNavigate();

  useEffect(() => {
    // Fetch job details from the API
    const fetchJob = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_PORT}/api/getJobData`,
          { jobId: jobId }, // Pass the job ID as a payload
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${uid}`, // Include authentication token
            },
          }
        );
        setJob(response.data); // Update state with the fetched job
        setAllowRender(true);
      } catch (err) {
        console.error("Error fetching job details:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchJob();
  }, [jobId, uid]);

  if(!allowRender){
    return null;
  }

  //   const updateJobStatus = (status) => {
  //     axios
  //       .patch(
  //         `${process.env.REACT_APP_PORT}/api/updateJob/${jobId}`,
  //         { status },
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${uid}`,
  //           },
  //         }
  //       )
  //       .then((response) => {
  //         setJob((prevJob) => ({
  //           ...prevJob,
  //           status,
  //         })); // Update job status in the state
  //       })
  //       .catch((error) => {
  //         console.error("Error updating job status:", error);
  //       });
  //   };

  //   const updateJobStatus = (jobId, status) => {
  //     axios
  //       .patch(
  //         `${process.env.REACT_APP_PORT}/api/updateJob/${jobId}`,
  //         { status },
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${uid}`, // Add token for authentication
  //           },
  //         }
  //       )
  //       .then((response) => {
  //         setCandidates(
  //           candidates.map((candidate) =>
  //             candidate.id === jobId ? { ...candidate, status } : candidate
  //           )
  //         );
  //       })
  //       .catch((error) => {
  //         toast.error("Error updating job status:", error);
  //         console.error("Error updating job status:", error);
  //       });
  //   };

  return (
    <>
      <div>
        {loading ? (
          <HashLoader
            color="#34a5f3"
            size={60} // Adjust the size as needed
            cssOverride={{
              position: "absolute", // Ensure the loader is positioned relative to the viewport
              top: "50%", // Center vertically
              left: "50%", // Center horizontally
              transform: "translate(-50%, -50%)", // Adjust to truly center the loader
            }}
            loading={true}
          />
        ) : (
          <div className="w-full">
            <Navbar />
            <div className="w-[95%] xl:mt-12 mt-8 mx-auto flex flex-col xl:gap-y-4 gap-y-2 xl:flex-row justify-between items-start ">

              {/* -------- back btn ---------- */}
              <div
                onClick={() => navigate(-1)}
                className="xl:w-[10%] w-max  flex items-center gap-x-2 cursor-pointer "
              >
                <span className="text-base">
                  <FaArrowLeftLong />
                </span>
                <p className="l xmd:text-xl text-lg">Back</p>
              </div>


              {/* ---------- job posting part -------- */}

              <div className="w-full xl:w-[90%] flex flex-col lg:gap-y-8 xs:gap-y-6 gap-y-4">

                {/* ------ job title ------ */}

                <div className="flex items-center justify-center">

                  <h2 className="text-center lg:text-[28px] text-xl md:font-semibold font-bold text-blue-200 uppercase">
                    {jobTitle}
                  </h2>

                </div>


                {/* --------- job details ------- */}

                <div className="flex flex-col xs:gap-y-6 gap-y-4 w-[90%] sm:w-full mx-auto">

                  {job ? (
                    <CandidateCard
                      key={job.id}
                      {...job}
                    //   updateJobStatus={updateJobStatus}
                    />
                  ) : (
                    <div>No job found.</div>
                  )}

                </div>

              </div>





            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Section8;
