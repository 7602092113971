import { React, useState } from "react";
// import { FaAngleRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { IoClose } from "react-icons/io5";

const Popup = ({ closePopup }) => {
  const navigate = useNavigate();

  // Handlers for routing
  const handleSearchJobClick = () => {
    navigate("/Frame-11"); // Replace with your actual route
  };

  const handlePostJobClick = () => {
    navigate("/Desktop-3"); // Replace with your actual route
  };

  // Close the popup when the user clicks outside of it
  return (
    <div className="fixed inset-0 bg-blue-50 xs:bg-black xs:bg-opacity-50 flex justify-center items-center z-10">


      <div className="hidden xs:block bg-white w-[460px] sm:w-[500px] md:w-[600px] md:h-[320px] sm:h-[340px] h-[320px] rounded-xl relative -z-10">

        {/* ------- top colour strip ------- */}

        {/* Top and Bottom colored strips */}
        <div className="absolute top-0 left-0 w-full h-12 bg-[#3D348B] rounded-t-xl text-white flex justify-end items-center text-3xl px-4">
          <div onClick={closePopup} className="cursor-pointer" >
            <IoClose />
          </div>

        </div>
        <div className="absolute bottom-0 left-0 w-full h-12 bg-[#3D348B] rounded-b-xl"></div>


        <div className="flex justify-between mt-12 h-[220px]  md:px-12 px-6 ">

          <div className="flex flex-col justify-center gap-y-6  ">

            <h2 className="sm:text-2xl text-xl text-blue-200 font-semibold">
              What are you looking for?
            </h2>

            <div className="flex flex-col gap-y-4">
              <button
                className="bg-blue-50 border border-blue-50 font-semibold py-3 px-4  rounded-full  focus:ring-2 focus:ring-indigo-400 w-full hover:border-blue-200 transition-all duration-300"
                onClick={handleSearchJobClick}
              >
                Search Job
              </button>

              {/* Post Job Button */}
              <button
                className="bg-blue-50 border border-blue-50 font-semibold py-3 px-4  rounded-full  focus:ring-2 focus:ring-indigo-400 w-full hover:border-blue-200 transition-all duration-300"
                onClick={handlePostJobClick}
              >
                Post Job
              </button>
            </div>

          </div>


          <div className="w-[100px] md:w-[120px] xmd:w-[150px]  flex items-center">
            <img
              src="./hirersLogo.png" // Use the path to your image here
              alt="Hirers Logo"
              className="w-full h-auto rounded-full"
            />
          </div>



        </div>

      </div>




      <div onClick={closePopup} className=" block xs:hidden cursor-pointer absolute top-10 xsm:right-6 right-10 text-black text-3xl " >
            <IoClose />
          </div>

      <div className="flex xs:hidden flex-col justify-center gap-y-6  px-12 ">

        <h2 className="sm:text-2xl text-xl text-blue-200 font-semibold">
          What are you looking for?
        </h2>

        <div className="flex flex-col gap-y-4 text-white">
          <button
            className="bg-blue-200  font-semibold py-3 px-4  rounded-full  focus:ring-2 focus:ring-indigo-400 w-full hover:scale-105 transition-all duration-300"
            onClick={handleSearchJobClick}
          >
            Search Job
          </button>

          {/* Post Job Button */}
          <button
            className="bg-blue-200 font-semibold py-3 px-4  rounded-full  focus:ring-2 focus:ring-indigo-400 w-full hover:scale-105 transition-all duration-300"
            onClick={handlePostJobClick}
          >
            Post Job
          </button>
        </div>

      </div>
    </div>
  );
};

const Navbar = () => {
  const navigate = useNavigate();
  // const handleJob = () => {
  //   navigate("/Frame-11");
  // };
  const handleContact = () => {
    navigate("/Contact");
  };
  // const handlePostJob = () => {
  //   navigate("/Desktop-3");
  // };

  const [showPopup, setShowPopup] = useState(false);

  const handleButtonClick = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <nav className="bg-blue-200 overflow-x-hidden w-[100%]">
      <div className=" flex justify-between items-center ">
        <div>
          <img
            className="sm:h-[80px] h-[70px] rounded-xl xs:pl-6 pl-4 py-1"
            src="/hirersLogo.png"
            alt="logo"
          ></img>
        </div>

        <div className="flex gap-x-4 items-center sm:pr-6 md:pr-10 pr-4">
          {/* <div
            className="text-white text-1xl sm:text-2xl px-2 sm:px-10 font-bold hover:text-rose-400 cursor-pointer"
            onClick={handlePostJob}
          >
            Post Job
          </div>
          <div
            className="text-white text-1xl sm:text-2xl px-2 sm:px-10 cursor-pointer font-bold hover:text-rose-400"
            onClick={handleJob}
          >
            New Job
          </div> */}
          <div
            className="text-white text-base sm:text-lg fnt-semibold  sm:px-10 cursor-pointer transition-all duration-300 hover:text-red-100"
            onClick={handleContact}
          >
            Contact Us
          </div>

          <div
            className="bg-white text-black sm:text-lg text-base rounded-3xl flex items-center px-4 xs:py-2 py-1 cursor-pointer font-semibold  hover:bg-gray hover:scale-105 transition-all duration-300"
            // onClick={handleProfile}
            onClick={handleButtonClick}
          >
            Signup/Login
            {/* <FaAngleRight className="ml-2" /> */}
          </div>
        </div>
      </div>
      {showPopup && <Popup closePopup={closePopup} />}
    </nav>
  );
};

export default Navbar;
