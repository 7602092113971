import React, { useState } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Hirers = () => {

  //Mushafiya -> Small the text and change some styling also add read more btn

  const desc1 = "At Hirers, we prioritize authenticity. All job postings on our platform are thoroughly verified, ensuring that job seekers have access to legitimate and high-quality opportunities within the BPO sector. This commitment to authenticity builds trust and reliability in our platform."

  const desc2 = "Our rigorous candidate screening process incorporates advanced AI-powered assessments to evaluate communication skills and other relevant competencies. This ensures that employers receive only the most qualified candidates, making the recruitment process more efficient and effective."

  const desc3 = "Hirers is dedicated to making job placements swift and hassle-free. Our streamlined processes and intelligent matching algorithms connect job seekers with suitable employers quickly, reducing the time and effort typically associated with job searches and hiring."

  const desc4 = "Our dedicated customer care team is always ready to assist both job seekers and employers.Whether you need help with the application process, have questions about job postings, or require support in any aspect of recruitment, our responsive team is here to ensure a smooth and positive experience on our platform."

  const [showDesc1, setShowDesc1] = useState(false);
  const [showDesc2, setShowDesc2] = useState(false);
  const [showDesc3, setShowDesc3] = useState(false);
  const [showDesc4, setShowDesc4] = useState(false);

  const descHandler = (number) => {

    if (number === 1) {
      setShowDesc1((prev) => !prev);
    }
    if (number === 2) {
      setShowDesc2((prev) => !prev);
    }
    if (number === 3) {
      setShowDesc3((prev) => !prev);
    }
    if (number === 4) {
      setShowDesc4((prev) => !prev);
    }

  }


  return (
    <div className="bg-primary-blue w-full  pb-12 font-inter overflow-x-hidden">
      <div>
        <h1 className=" pt-10 font-bold text-center text-white text-2xl sm:text-3xl md:text-4xl tracking-wider py-6 mt-10 ">
          "Why HIRERS?"
        </h1>
      </div>

      <div className="realtive hidden lg:flex flex-row justify-center mt-3 gap-y-7 gap-x-5 w-[95%] border">
        <div className="relative group flex flex-col items-center bg-primary-blue text-white p-4   w-[22%]  ">
          <LazyLoadImage
            effect="blur"
            className="h-20 sm:h-24 lg:h-32 mb-4 "
            src="/job listing.png"
            alt="Verified Job Listings"
          />
          <div className="absolute top-2/5 -right-[50px] w-40 z-9 overflow-clip">
            <LazyLoadImage
              effect="blur"
              className="w-44"
              src="/jobListing_white-arrow.png"
              alt="Arrow"
            />
          </div>
          <h2 className="text-center text-purple-300 font-bold text-lg sm:text-xl ">
            Verified Job Listings
          </h2>
          <p className="text-sm sm:text-base text-center opacity-80 group-hover:opacity-100 transition-opacity duration-300 mt-2">
            {
              !showDesc1 ? desc1.length > 200 ? desc1.slice(0, 200) + "..." : desc1 : desc1 + "..."
            }
            <span
              onClick={() => descHandler(1)}
              className="text-red-100 cursor-pointer">
              {showDesc1 === true ? "show less" : "read more"}
            </span>
          </p>
        </div>

        <div className="relative group flex flex-col items-center bg-primary-blue text-white p-4  w-[22%] ">
          <LazyLoadImage
            effect="blur"
            className="h-20 sm:h-24 lg:h-32 mb-4"
            src="/candidate screening.png"
            alt="Candidate Screening"
          />
          <div className="absolute top-2/5 -right-[50px] w-40 z-9 overflow-clip">
            <LazyLoadImage
              effect="blur"
              className="w-44"
              src="/jobListing_white-arrow.png"
              alt="Arrow"
            />
          </div>
          <h2 className="text-center text-purple-300 font-bold text-lg sm:text-xl ">
            Rigorous Candidate Screening
          </h2>
          <p className="text-sm sm:text-base text-center  opacity-80 group-hover:opacity-100 transition-opacity duration-300 ">
            {
              !showDesc2 ? desc2.length > 180 ? desc2.slice(0, 180) + "..." : desc2 : desc2 + "..."
            }
            <span
              onClick={() => descHandler(2)}
              className="text-red-100 cursor-pointer">
              {showDesc2 === true ? "show less" : "read more"}
            </span>
          </p>
        </div>

        <div className="relative group flex flex-col items-center bg-primary-blue text-white p-4 rounded-lg  w-[22%]  ">
          <LazyLoadImage
            effect="blur"
            className="h-20 sm:h-24 lg:h-32 mb-4"
            src="/job placement.png"
            alt="Job Placement"
          />
          <div className="absolute top-2/5 -right-[50px] w-40 z-9 overflow-clip">
            <LazyLoadImage
              effect="blur"
              className="w-44"
              src="/jobListing_white-arrow.png"
              alt="Arrow"
            />
          </div>
          <h2 className="text-center text-purple-300 font-bold text-lg sm:text-xl ">
            Swift Job Placement
          </h2>
          <p className="text-sm sm:text-base text-center opacity-80 group-hover:opacity-100 transition-opacity duration-300">
            {
              !showDesc3 ? desc3.length > 200 ? desc3.slice(0, 200) + "..." : desc3 : desc3 + "..."
            }
            <span
              onClick={() => descHandler(3)}
              className="text-red-100 cursor-pointer">
              {showDesc3 === true ? "show less" : "read more"}
            </span>
          </p>
        </div>

        <div className="relative group flex flex-col items-center bg-primary-blue text-white p-4 rounded-lg   w-[22%]  ">
          <LazyLoadImage
            effect="blur"
            className="h-20 sm:h-24 lg:h-32 mb-4"
            src="/customer suport.png"
            alt="Customer Care"
          />
          <h2 className="text-center text-purple-300 font-bold text-lg sm:text-xl ">
            Responsive Customer Care Team
          </h2>
          <p className="text-sm sm:text-base opacity-80 group-hover:opacity-100 text-center transition-opacity duration-300">
          {
              !showDesc4 ? desc4.length > 160 ? desc4.slice(0, 160) + "..." : desc4 : desc4 + "..."
            }
            <span
              onClick={() => descHandler(4)}
              className="text-red-100 cursor-pointer">
              {showDesc4 === true ? "show less" : "read more"}
            </span>    
          </p>
        </div>
      </div>





      <div className="lg:hidden grid grid-cols-3 grid-rows-3 gap-3 sm:h-screen p-4 font-inter bg-gray-200 mx-auto w-full">

        <div className="flex items-center justify-center">
          <LazyLoadImage
            effect="blur"
            className="h-full w-full mb-1"
            src="/arr1.png"
            alt="job listing"
          />
        </div>

        <div className="h-44 w-28 flex justify-center items-center mx-auto bg-blue-500 text-white p-2 flex-col aspect-square">
          <img
            className="h-full w-full mb-1"
            src="/job listing.png"
            alt="job listing"
          />
          <h2 className="text-center text-purple-300 font-semibold sm:text-xl">
            Verified Job Listings
          </h2>
        </div>

        <div className="flex justify-center items-center">
          <LazyLoadImage
            effect="blur"
            className="h-full w-full mb-1"
            src="/arr2.png"
            alt="job listing"
          />
        </div>

        <div className="h-44 w-28 flex justify-center items-center mx-auto bg-purple-500 text-white p-2 flex-col aspect-square">
          <img
            className="h-full w-full mb-4"
            src="/customer suport.png"
            alt="customer support"
          />
          <h2 className="text-center text-purple-300 font-semibold sm:text-xl">
            Responsive Customer Care Team
          </h2>
        </div>

        <div className="flex justify-center items-center"></div>

        <div className="h-44 w-28 flex justify-center items-center mx-auto bg-green-500 text-white p-2 flex-col aspect-square">
          <LazyLoadImage
            effect="blur"
            className="h-full max-w-full mb-4"
            src="/candidate screening.png"
            alt="candidate screening"
          />
          <h2 className="text-center text-purple-300 font-semibold sm:text-xl">
            Rigorous Candidate Screening
          </h2>
        </div>

        <div className="flex justify-center items-center ">
          <LazyLoadImage
            effect="blur"
            className="h-full w-full mb-1"
            src="/arr4.png"
            alt="job listing"
          />
        </div>

        <div className="h-44 w-28 flex justify-center items-center mx-auto bg-orange-500 text-white p-2 flex-col aspect-square">
          <LazyLoadImage
            effect="blur"
            className="h-full max-w-full mb-4"
            src="/job placement.png"
            alt="job placement"
          />
          <h2 className="text-center text-purple-300 font-semibold sm:text-xl">
            Swift Job Placement
          </h2>
        </div>

        <div className="flex justify-center items-center ">
          <LazyLoadImage
            effect="blur"
            className="h-full w-full mb-1"
            src="/arr3.png"
            alt="job listing"
          />
        </div>

        <div className="flex justify-center items-center mx-auto"></div>
      </div>
    </div>
  );
};

export default Hirers;
