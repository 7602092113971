import React from "react";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const Refund = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen p-5">
      <div className="max-w-4xl mx-auto p-4 relative">
        {/* Back Button */}
        <button
          onClick={() => navigate("/")}
          className="absolute sm:top-4 top-0 sm:left-4 left-1 flex items-center gap-2 text-primary-blue text-lg sm:text-2xl"
        >
          <IoArrowBackCircleSharp className="text-2xl sm:text-3xl" />
          <span>Back</span>
        </button>

        {/* Refund Policy Content */}
        <h1 className="text-2xl sm:text-4xl font-bold mb-4 text-center sm:mt-9">
          Cancellation and Refund Policy for Recruiters
        </h1>
        <p className="text-gray-700 leading-relaxed">
          At Hirers, we are committed to providing seamless and efficient
          services for recruiters. Please review our cancellation and refund
          policy below:
        </p>

        <h2 className="text-xl font-semibold mt-6">1. Cancellation Policy</h2>
        <h3 className="text-lg font-medium mt-4">1.1 Job Post Cancellations</h3>
        <ul className="list-disc pl-6 mt-2 space-y-2 text-gray-700">
          <li>
            Recruiters can cancel a job post before it is published by
            contacting our support team or using the cancellation option
            available in the dashboard.
          </li>
          <li>
            Once a job post is live, cancellations will not be allowed, and no
            refunds will be issued.
          </li>
        </ul>

        <h2 className="text-xl font-semibold mt-6">2. Refund Policy</h2>
        <h3 className="text-lg font-medium mt-4">
          2.1 Eligibility for Refunds
        </h3>
        <ul className="list-disc pl-6 mt-2 space-y-2 text-gray-700">
          <li>
            The recruiter was charged incorrectly or for services not availed.
          </li>
          <li>
            A technical issue on Hirers’ platform prevented the recruiter from
            using the subscribed service.
          </li>
          <li>
            The refund request is initiated within 7 days of the transaction.
          </li>
        </ul>
        <h3 className="text-lg font-medium mt-4">
          2.2 Non-Refundable Services
        </h3>
        <ul className="list-disc pl-6 mt-2 space-y-2 text-gray-700">
          <li>Fees for published job posts are non-refundable.</li>
          <li>
            Subscription fees are non-refundable once the subscription period
            has started.
          </li>
        </ul>
        <h3 className="text-lg font-medium mt-4">2.3 Refund Process</h3>
        <ul className="list-disc pl-6 mt-2 space-y-2 text-gray-700">
          <li>
            Refund requests must be submitted via email at{" "}
            <a href="mailto:info@hirers.co.in" className="text-primary-blue">
              info@hirers.co.in
            </a>{" "}
            with proof of payment and details of the issue.
          </li>
          <li>
            Approved refunds will be processed within 5-7 business days after
            review.
          </li>
        </ul>

        <h2 className="text-xl font-semibold mt-6">3. Exceptions</h2>
        <ul className="list-disc pl-6 mt-2 space-y-2 text-gray-700">
          <li>
            In cases of errors caused by Hirers, such as duplicate charges or
            incorrect billing, a full refund will be issued.
          </li>
          <li>
            Refunds will not be issued for recruiter errors, such as incorrect
            job post details or failure to use the platform’s features.
          </li>
        </ul>

        <h2 className="text-xl font-semibold mt-6">4. Contact Us</h2>
        <p className="text-gray-700 mt-2">
          For assistance with cancellations or refunds, please contact us:
        </p>
        <p className="text-gray-700 mt-1">
          Email:{" "}
          <a href="mailto:info@hirers.co.in" className="text-primary-blue">
            info@hirers.co.in
          </a>
        </p>
      </div>
    </div>
  );
};

export default Refund;
