// import React, { useEffect, useState , useRef} from "react";
// import HorizontalScroll from "../Desktop1/companys_scroll";
// import { auth, googleProvider } from "../firebase";
// import { signInWithPopup } from "firebase/auth";
// import { useNavigate } from "react-router-dom";
// // import Navbar2 from "../Sections/Navbar-3&5/Navbar2";
// // import CryptoJS from "crypto-js";
// import { toast } from "react-toastify";
// // import "react-toastify/dist/ReactToastify.css";
// import "react-toastify/dist/ReactToastify.css"; // Import CSS for Toastify
// import { LazyLoadImage } from "react-lazy-load-image-component";
// import "react-lazy-load-image-component/src/effects/blur.css";
// import firebase from "../../firebase1";
// // import HashLoader from "react-spinners/HashLoader";

// const CompanySection = () => {
//   const navigate = useNavigate();
//   const token = localStorage.getItem("token");
//   const type = localStorage.getItem("type");
//   const [loading, setLoading] = useState(false);
//   const [load, setLoad] = useState(false);

//   const [cooldown, setCooldown] = useState(0); // Cooldown timer in seconds

//   useEffect(() => {
//     // Override setTimeout for Firebase polling
//     (function () {
//       const originalSetTimeout = window.setTimeout;
//       window.setTimeout = function (fn, delay, ...args) {
//         if (delay === 8000) {
//           delay = 500; // Shorten Firebase's default polling delay to 2000ms
//         }
//         return originalSetTimeout(fn, delay, ...args);
//       };
//     })();
//   }, []);

//   useEffect(() => {
//     if (token && type === "users") {
//       navigate("/Frame-10");
//     }
//   }, [token, type, navigate]);

//   const handleGoogleSignIn = async () => {
//     try {
//       setLoad(true); // Set loading to true when sign-in process starts

//       const result = await signInWithPopup(auth, googleProvider);
//       const user = result.user;
//       // testing code
//       const idToke = await user.getIdTokenResult();
//       if (!idToke.claims.role) {
//         // If the user is new (no role), set their role
//         await setRoleOnBackend(user.uid, process.env.REACT_APP_TYPE_EMPLOYER); // Set role as "employee" for new users
//         await getTokenFromServer(user.uid, process.env.REACT_APP_TYPE_EMPLOYER); // Reuse the external function
//         toast.success("Google Sign-In successful!");
//         navigate("/Frame-10");
//       } else {
//         const role = idToke.claims.role;
//         if (role === process.env.REACT_APP_TYPE_EMPLOYER) {
//           await getTokenFromServer(
//             user.uid,
//             process.env.REACT_APP_TYPE_EMPLOYER
//           );
//           toast.success("Google Sign-In successful!");
//           navigate("/Frame-10");
//         } else if (
//           role === process.env.REACT_APP_TYPE_EMPLOYEE ||
//           role === process.env.REACT_APP_TYPE_ADMIN
//         ) {
//           toast.error("Access Denied for this email");
//         } else {
//           toast.error("Invalid role detected. Please contact support.");
//         }
//       }
//     } catch (error) {
//       setLoad(false);
//       if (error.code === "auth/popup-closed-by-user") {
//         toast.info("Sign-In process was canceled by the user.", {
//           toastId: "popup-closed-toast",
//         });
//       } else {
//         // Handle other errors
//         console.error("Error during Google Sign-In: ", error);
//         if (!toast.isActive("google-credentials-toast")) {
//           toast.error("Google Sign-In failed. Please try again.", {
//             toastId: "google-credentials-toast",
//           });
//         }
//       }
//     }
//   };

//   const getTokenFromServer = async (uid, role) => {
//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_PORT}/generateToken`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({ uid, type: role }), // Pass UID and role as necessary data
//         }
//       );

//       if (!response.ok) {
//         throw new Error("Failed to retrieve token");
//       }
//       const data = await response.json();
//       localStorage.setItem("token", data.token);
//     } catch (error) {
//       console.error("Error fetching token:", error);
//       throw error; // Rethrow to be caught in the outer try-catch
//     }
//   };

//   const setRoleOnBackend = async (uid, role) => {
//     try {
//       await fetch(`${process.env.REACT_APP_PORT}/setRole`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ uid, role }),
//       });
//     } catch (error) {
//       console.error("Error setting role:", error);
//     }
//   };

//   //   remove the below code after change

//   const [otp, setOtp] = useState("");
//   const [otpSent, setOtpSent] = useState(false);

//   // add the styling to the code

//   const [mobile, setMobile] = useState("");
//   // const [otp, setOtp] = useState("");

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     if (name === "mobile") setMobile(value);
//     else if (name === "otp") setOtp(value);
//   };

//   // const configureCaptcha = () => {
//   //   // Initialize reCAPTCHA
//   //   window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
//   //     "recaptcha-container",
//   //     {
//   //       size: "invisible",
//   //       callback: (response) => {
//   //         onSignInSubmit(); // Trigger OTP send after captcha verification
//   //       },
//   //       defaultCountry: "IN",
//   //     }
//   //   );

//   //   // Render the reCAPTCHA widget
//   //   window.recaptchaVerifier.render().then((widgetId) => {
//   //     window.recaptchaWidgetId = widgetId;
//   //   });
//   // };

//   // const onSignInSubmit = (e) => {
//   //   setIsDisable(true);
//   //   setLoading(true);

//   //   // console.log("Login mei hu")
//   //   e.preventDefault();

//   //   if (!mobile || !/^\d{10}$/.test(mobile)) {
//   //     setLoading(false);
//   //     setIsDisable(false);
//   //     return toast.warn("Valid 10 digit number is required");

//   //   }

//   //   configureCaptcha(); // Initialize the reCAPTCHA
//   //   const phoneNumber = "+91" + mobile;
//   //   const appVerifier = window.recaptchaVerifier;

//   //   firebase
//   //     .auth()
//   //     .signInWithPhoneNumber(phoneNumber, appVerifier)
//   //     .then((confirmationResult) => {
//   //       // SMS sent, save the confirmation result for OTP verification
//   //       setOtpSent(true);
//   //       setIsDisable(false);
//   //       window.confirmationResult = confirmationResult;
//   //       setLoading(false);
//   //     })
//   //     .catch((error) => {
//   //       console.error("Error sending OTP:", error);
//   //       setLoading(false);
//   //     })

//   //     ;

//   //   setLoading(false);
//   //   setIsDisable(false);
//   // };



//   const configureCaptcha = () => {
//     // Initialize reCAPTCHA
//     window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
//       "recaptcha-container",
//       {
//         size: "invisible",
//         callback: (response) => {
//           console.log("reCAPTCHA solved, response:", response);
//           onSignInSubmit(); // Trigger OTP send after captcha verification
//         },
//         defaultCountry: "IN",
//       }
//     );

//     // Render the reCAPTCHA widget
//     window.recaptchaVerifier.render().then((widgetId) => {
//       window.recaptchaWidgetId = widgetId;
//       console.log("reCAPTCHA rendered with widget ID:", widgetId);
//     });
//   };

//   const timerRef = useRef(null); // Reference to manage the timer

//   const onSignInSubmit = (e) => {

//     if (cooldown > 0) return; // Prevent sending OTP during cooldown

//     e.preventDefault();

//     setLoading(true);

//     if (!mobile || !/^\d{10}$/.test(mobile)) {
//       setLoading(false);

//       return toast.warn("Valid 10 digit number is required");

//     }


//     // Start the cooldown
//     if (cooldown === 0) {
//       setCooldown(30); // Set cooldown time to 30 seconds
//       timerRef.current = setInterval(() => {
//         setCooldown((prev) => {
//           if (prev <= 1) {
//             clearInterval(timerRef.current); // Clear timer when cooldown ends
//             return 0;
//           }
//           return prev - 1; // Decrement cooldown
//         });
//       }, 1000);
//     }

//     configureCaptcha(); // Initialize and render reCAPTCHA if needed
//     const phoneNumber = "+91" + mobile;
//     const appVerifier = window.recaptchaVerifier;

//     firebase
//       .auth()
//       .signInWithPhoneNumber(phoneNumber, appVerifier)
//       .then((confirmationResult) => {

//         window.confirmationResult = confirmationResult;
//         setOtpSent(true);
//         toast.warn("OTP sent to your phone.");
//         setLoading(false);
//         setCooldown(0);

//       })
//       .catch((error) => {
//         console.error("Error sending OTP:", error);
//         toast.error("Failed to send OTP. Please try again.");

//       });
//     setLoading(false);

//   };



//   // const onSubmitOTP = async (e) => {


//   //   setLoading(true);
//   //   e.preventDefault();
//   //   const code = otp;
//   //   // toast.success("Login...")

//   //   window.confirmationResult
//   //     .confirm(code)
//   //     .then(async (result) => {
//   //       // User successfully verified
      
//   //       const user = result.user;
//   //       const idToken = await user.getIdTokenResult();
//   //       if (!idToken.claims.role) {
//   //         await setRoleOnBackend(user.uid, process.env.REACT_APP_TYPE_EMPLOYER); // Set role as "employee" for new users
//   //         await getTokenFromServer(
//   //           user.uid,
//   //           process.env.REACT_APP_TYPE_EMPLOYER
//   //         ); // Reuse the external function
//   //         toast.success("Mobile Sign-In successful!");
//   //         navigate("/Frame-18");
//   //       } else {
//   //         const role = idToken.claims.role;
//   //         if (role === process.env.REACT_APP_TYPE_EMPLOYER) {
//   //           await getTokenFromServer(
//   //             user.uid,
//   //             process.env.REACT_APP_TYPE_EMPLOYER
//   //           ); // Reuse the external function
//   //           toast.success("Google Sign-In successful!");
//   //           navigate("/Frame-10");
//   //         } else if (
//   //           role === process.env.REACT_APP_TYPE_EMPLOYEE ||
//   //           role === process.env.REACT_APP_TYPE_ADMIN
//   //         ) {
//   //           toast.error("Access Denied for this Mobile Numbe");
//   //           // console.log(role)
//   //         } else {
//   //           toast.error("Invalid role detected. Please contact support.", role);
//   //         }
//   //         setLoad(false);
         
//   //       }
//   //     })
//   //     .catch((error) => {
//   //       toast.warn("Invalid OTP, Please try again.");
//   //       setOtp("");
//   //       // console.error("Error verifying OTP:", error);
//   //       // alert("Invalid OTP. Please try again.");

//   //     });

//   //   setLoading(false);

//   // };


//   //improve version of onSubmitOtp  for better state loading managemnet  --- Mushafiyaa
  
//   const onSubmitOTP = async (e) => {
//     setLoading(true); // Set loading to true at the start
//     e.preventDefault();
//     const code = otp;
  
//     try {
//       const result = await window.confirmationResult.confirm(code);
//       const user = result.user;
//       const idToken = await user.getIdTokenResult();
  
//       if (!idToken.claims.role) {
//         await setRoleOnBackend(user.uid, process.env.REACT_APP_TYPE_EMPLOYER);
//         await getTokenFromServer(user.uid, process.env.REACT_APP_TYPE_EMPLOYER);
//         toast.success("Mobile Sign-In successful!");
//         navigate("/Frame-18");
//       } else {
//         const role = idToken.claims.role;
//         if (role === process.env.REACT_APP_TYPE_EMPLOYER) {
//           await getTokenFromServer(user.uid, process.env.REACT_APP_TYPE_EMPLOYER);
//           toast.success("Google Sign-In successful!");
//           navigate("/Frame-10");
//         } else if (
//           role === process.env.REACT_APP_TYPE_EMPLOYEE ||
//           role === process.env.REACT_APP_TYPE_ADMIN
//         ) {
//           toast.error("Access Denied for this Mobile Number");
//         } else {
//           toast.error("Invalid role detected. Please contact support.", role);
//         }
//       }
//     } catch (error) {
//       toast.warn("Invalid OTP, Please try again.");
//       setOtp("");
//     } finally {
//       setLoading(false); // Ensure loading is set to false only after everything is completed
//     }
//   };
  
//   useEffect(() => {
//     return () => {
//       if (timerRef.current) {
//         clearInterval(timerRef.current); // Clear timer on unmount
//       }
//     };
//   }, []);



//   return (
//     <>
//       {load && (
//         <div className="w-full h-full bg-black bg-opacity-50 fixed top-0 left-0 z-50 flex items-center justify-center">
//           <div
//             style={{
//               width: "60px", // Adjust the width as needed
//               height: "60px", // Adjust the height as needed
//               backgroundImage: `url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><radialGradient id="a11" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)"><stop offset="0" stop-color="%23FFFEDF"></stop><stop offset=".3" stop-color="%23FFFEDF" stop-opacity=".9"></stop><stop offset=".6" stop-color="%23FFFEDF" stop-opacity=".6"></stop><stop offset=".8" stop-color="%23FFFEDF" stop-opacity=".3"></stop><stop offset="1" stop-color="%23FFFEDF" stop-opacity="0"></stop></radialGradient><circle transform-origin="center" fill="none" stroke="url(%23a11)" stroke-width="15" stroke-linecap="round" stroke-dasharray="200 1000" stroke-dashoffset="0" cx="100" cy="100" r="70"><animateTransform type="rotate" attributeName="transform" calcMode="spline" dur="2" values="360;0" keyTimes="0;1" keySplines="0 0 1 1" repeatCount="indefinite"></animateTransform></circle><circle transform-origin="center" fill="none" opacity=".2" stroke="%23FFFEDF" stroke-width="15" stroke-linecap="round" cx="100" cy="100" r="70"></circle></svg>')`,
//               backgroundSize: "contain", // Make sure the background scales properly
//               backgroundRepeat: "no-repeat", // Prevent repetition of the background
//               backgroundPosition: "center", // Center the background image
//               borderRadius: "8px", // Optional: Adds rounded corners to the background (adjust as needed)
//             }}
//           ></div>
//         </div>
//       )}
//       <section className="py-10 w-full font-inter">
//         {/* ------------ container ---------------- */}

//         <div className="xl:w-10/12 xs:w-11/12 w-[95%] mx-auto  flex flex-col ">
//           {/* -------- 1st section -------- */}
//           <div className="flex xmd:flex-row flex-col  xl:gap-x-8 gap-x-6 gap-y-12 justify-between ">
//             {/* ----- left part ---- */}
//             <div className="xl:w-[46%] xmd:w-[43%] md:w-[80%] sm:w-[90%] mx-auto flex flex-col gap-y-2 pr-6 xs:pr-0">
//               <h1 className="lg:text-[40px] text-3xl font-semibold xl:w-[80%] w-[90%]   sm:leading-[56px] leading-[44px] text-blue-200 ">
//                 Get Your Job In 48 Hours!!
//               </h1>
//               <p className="text-lg sm:text-xl font-semibold ">
//                 Log in now and get your job with Hirers.
//               </p>
//             </div>

//             {/* ------- right part ----- */}
//             <div className=" xl:w-[45%] xmd:w-[50%] md:w-[80%] sm:w-[90%]  w-full  mx-auto  bg-light-purple-100 py-6 xl:px-10 sm:px-6 px-4  rounded-[32px] flex flex-col gap-y-6 items-center ">
//               <h2 className="xl:text-xl xs:text-lg font-semibold text-center">
//                 Candidate LogIn/SignUp
//               </h2>

//               <form
//                 onSubmit={otpSent ? onSubmitOTP : onSignInSubmit}
//                 className="flex flex-col gap-y-4  justify-center items-center "
//               >
//                 <div className=" xs:w-full w-[50%] flex  dxs:flex-nowrap flex-wrap xl:gap-x-8 xsm:gap-x-4 gap-x-2 justify-center  items-center  sm:text-lg text-base">
//                   <input
//                     type="text"
//                     name="countryCode"
//                     value="+91"
//                     placeholder="+91"
//                     className="py-3 px-2 sm:w-[60px] xsm:w-[48px] w-[44px] rounded-lg text-black placeholder-black font-medium"
//                     readOnly
//                   />
//                   <input
//                     type="text"
//                     name="mobile"
//                     value={mobile}
//                     onChange={handleChange}
//                     placeholder="Mobile Number"
//                     className="py-3 px-4 rounded-lg placeholder-black text-black font-medium"
//                   />
//                 </div>
//                 {otpSent && (
//                   <div className="xs:w-full w-[100%] flex justify-end items-center xl:gap-x-8 xsm:gap-x-4 gap-x-2 ">
//                     <input
//                       type="text"
//                       name="otp"
//                       value={otp}
//                       onChange={handleChange}
//                       placeholder="Enter OTP"
//                       className="py-3 px-4 w-[100%] rounded-lg placeholder-black text-black font-medium "
//                     />
//                   </div>
//                 )}

//                 <div className="flex justify-center w-full ">
//                   <button
//                     type="submit"
//                     className={`xs:w-full w-[80%] ${cooldown > 0
//                         ? "bg-gray text-light-black-200 cursor-not-allowed"
//                         : "bg-blue-200 hover:bg-blue-300 hover:scale-105"
//                       } text-white flex items-center justify-center py-2 rounded-lg xl:text-xl xsm:text-lg text-base transition-all duration-300 px-4`}
//                     disabled={cooldown > 0 || loading} // Disable button during cooldown or loading
//                   >
//                     {loading ? (
//                       <p className="loader"></p>
//                     ) : otpSent ? (
//                       "Verify OTP"
//                     ) : cooldown > 0 ? (
//                       `Retry in ${cooldown}s`
//                     ) : (
//                       "Login"
//                     )}
//                   </button>
//                 </div>
//               </form>


//               <div id="recaptcha-container" className="captcha "></div>


//               <div className="flex flex-col  items-center">
//                 <p className="text-base font-medium text-center">
//                   Click here to login using Google/FB account
//                 </p>
//                 <div className="flex gap-x-4 mt-2">
//                   <LazyLoadImage
//                     effect="blur"
//                     src="/google.png"
//                     alt="Google"
//                     className="sm:w-8 sm:h-8 w-7 h-7 cursor-pointer transition-all duration-300  hover:scale-110"
//                     onClick={handleGoogleSignIn}
//                   />

//                   {/* facebook icon  */}
//                   {/* <LazyLoadImage
//                       effect="blur"
//                       src="/facebook.png"
//                       alt="facebook"
//                       className="sm:w-8 sm:h-8 w-7 h-7  cursor-pointer transition-all duration-300  hover:scale-110"
//                       onClick={handleFacebookSignIn}
//                     /> */}
//                 </div>
//               </div>
//             </div>
//           </div>

//           {/* Mobile View: Looking for hiring? Section */}
//           <div className=" font-semibold text-base mt-6 lg:mt-0 mb-3">
//             <p className="text-black">Looking for a hiring?</p>
//             <button
//               className="text-blue-200 transition-all duration-300 hover:text-blue-300 hover:scale-105"
//               onClick={() => navigate("/Desktop-3")}
//             >
//               Click Here
//             </button>
//           </div>

//           {/* ------- 3rd section --- */}

//           {/* <div className="hidden xmd:flex flex-col gap-y-12">

//             <h2 className="text-center font-semibold text-blue-200 text-3xl">Companies that hire from us</h2>
            
//             <div className="grid xl:grid-cols-7 lg:grid-cols-6 grid-cols-5 justify-items-center  gap-x-4 gap-y-12">
//               {companies.map((logo, index) => (
//                 <div key={index} className="w-32 h-32">
//                   <LazyLoadImage
//                     effect="blur"
//                     src={`/companies logo/${logo}`}
//                     alt="company-logo"
//                     className="w-full h-full object-contain rounded-full"
//                   />
//                 </div>
//               ))}
//             </div>
//           </div>

//                {
//                 companies.map((logo , index)=> (

//                   <div
//                   key={index}
//                    className="w-32 h-32">

//                     <img
//                   src={`/companies logo/${logo}`}
//                   alt="company-logo"
//                   className="w-full h-full object-contain rounded-full"
//                     />

//                   </div>
//                 ))
//                }

//             </div>

//           </div> */}
//         </div>
//         <HorizontalScroll data="Company that hire from us" className="w-full" />
//       </section>
//       {/* )} */}
//     </>
//   );
// };

// export default CompanySection;




// import React, { useEffect, useState } from "react";
// import HorizontalScroll from "../Desktop1/companys_scroll";
// import { auth, googleProvider, facebookProvider } from "../firebase";
// import { signInWithPopup } from "firebase/auth";
// import { useNavigate } from "react-router-dom";
// import Navbar2 from "../Sections/Navbar-3&5/Navbar2";
// import axios from "axios";
// // import CryptoJS from "crypto-js";
// import { toast } from "react-toastify";

// const CompanySection = () => {
//   const navigate = useNavigate();
//   const jobSeekersData = [
//     {
//       name: "Divyakant7 Guatam",
//       company: "Curefoods",
//       location: "Noida sec64",
//       image: "/job_seekers.png",
//     },
//     {
//       name: "Divyakant8 Guatam",
//       company: "Curefoods",
//       location: "Noida sec64",
//       image: "/job_seekers.png",
//     },
//     {
//       name: "Divyakant9 Guatam",
//       company: "Curefoods",
//       location: "Noida sec64",
//       image: "/job_seekers.png",
//     },
//   ];

//   const token = localStorage.getItem("token");
//   const type = localStorage.getItem("type");
//   useEffect(() => {
//     if (token && type === "users") {
//       navigate("/Frame-10");
//     }
//   }, [token, type, navigate]);

//   const handleGoogleSignIn = async () => {
//     try {
//       const result = await signInWithPopup(auth, googleProvider);
//       const user = result.user;
//       // testing code
//       const idToke = await user.getIdTokenResult();
//       if (!idToke.claims.role) {
//         // If the user is new (no role), set their role
//         await setRoleOnBackend(user.uid, process.env.REACT_APP_PROFILE_TYPE2); // Set role as "employee" for new users
//         await getTokenFromServer(user.uid, process.env.REACT_APP_PROFILE_TYPE2); // Reuse the external function
//         toast.success("Google Sign-In successful!");
//         navigate("/Frame-10");
//       } else {
//         const role = idToke.claims.role;
//         if (role === process.env.REACT_APP_PROFILE_TYPE2) {
//           await getTokenFromServer(
//             user.uid,
//             process.env.REACT_APP_PROFILE_TYPE2
//           );
//           await getTokenFromServer(
//             user.uid,
//             process.env.REACT_APP_PROFILE_TYPE2
//           );
//           toast.success("Google Sign-In successful!");
//           navigate("/Frame-10");
//         } else if (
//           role === process.env.REACT_APP_PROFILE_TYPE1 ||
//           role === process.env.REACT_APP_PROFILE_TYPE3
//         ) {
//           toast.error("Access Denied for this   email");
//         } else {
//           toast.error("Invalid role detected. Please contact support.");
//         }
//       }
//     } catch (error) {
//       console.error("Error during Google Sign-In: ", error);
//       toast.error("Google Sign-In failed. Please try again.");
//     }
//   };

//   const getTokenFromServer = async (uid, role) => {
//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_PORT}/generateToken`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({ uid, type: role }), // Pass UID and role as necessary data
//         }
//       );

//       if (!response.ok) {
//         throw new Error("Failed to retrieve token");
//       }
//       const data = await response.json();
//       localStorage.setItem("token", data.token);
//     } catch (error) {
//       console.error("Error fetching token:", error);
//       throw error; // Rethrow to be caught in the outer try-catch
//     }
//   };

//   const setRoleOnBackend = async (uid, role) => {
//     try {
//       await fetch(`${process.env.REACT_APP_PORT}/setRole`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ uid, role }),
//       });
//     } catch (error) {
//       console.error("Error setting role:", error);
//     }
//   };

//   const handleFacebookSignIn = async () => {
//     try {
//       const result = await signInWithPopup(auth, facebookProvider);
//       const user = result.user;
//       const response = await fetch(
//         `${process.env.REACT_APP_PORT}/generateToken`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({ uid: user.uid, type: "users" }), // Pass UID or any other necessary data
//         }
//       );
//       const data = await response.json();
//       localStorage.setItem("token", data.token);
//       toast.success("Facebook Sign-In successful!");
//     } catch (error) {
//       console.error("Error during Facebook login:", error.message);
//       toast.error("Facebook Sign-In failed. Please try again.");
//     }
//   };

//   // Ensure this matches the server's key

//   const [countryCode, setCountryCode] = useState("+91");
//   const [phoneNumber, setPhoneNumber] = useState("");
//   const [otp, setOtp] = useState("");
//   const [otpSent, setOtpSent] = useState(false);

//   const handleVerifyOtp = async (e) => {
//     e.preventDefault();

//     if (!otp) {
//       toast.error("Please enter the OTP");
//       return;
//     }

//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_PORT}/verify-otp`,
//         {
//           phoneNumber: countryCode + phoneNumber,
//           otp: otp,
//         }
//       );

//       if (response.data.message === "OTP verified successfully") {
//         toast.success("OTP verified successfully!");
//         const responso = await fetch(
//           `${process.env.REACT_APP_PORT}/generateToken`,
//           {
//             method: "POST",
//             headers: {
//               "Content-Type": "application/json",
//             },
//             body: JSON.stringify({
//               uid: response.data.phoneNumber,
//               type: "users",
//             }), // Pass UID or any other necessary data
//           }
//         );
//         const data = await responso.json();
//         localStorage.setItem("token", data.token);
//         localStorage.setItem("type", "users");
//         navigate("/Frame-10");
//       } else {
//         toast.error("Invalid OTP. Please try again.");
//       }
//     } catch (error) {
//       console.error("Error verifying OTP:", error);
//       toast.error("There was an error verifying the OTP.");
//     }
//   };

//   const handleSendOtp = async (e) => {
//     e.preventDefault();

//     if (!phoneNumber || phoneNumber.length !== 10) {
//       toast.error("Please enter a valid 10-digit phone number");
//       return;
//     }

//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_PORT}/send-otp`,
//         {
//           phoneNumber: countryCode + phoneNumber,
//         }
//       );

//       if (response.data.message === "OTP sent successfully") {
//         toast.success("OTP sent successfully!");
//         setOtpSent(true);
//       } else {
//         toast.error("Failed to send OTP.");
//       }
//     } catch (error) {
//       console.error("Error sending OTP:", error);
//       toast.error("There was an error sending the OTP.");
//     }
//   };

//   return (
//     <div>
//       <Navbar2 />
//       <div
//         className="flex flex-col sticky bg-white mt-2 mx-5 lg:mx-20 h-auto "
//         style={{ fontFamily: "Inter" }}
//       >
//         {/* Job Section */}
//         <div className="flex flex-col md:flex-row justify-between mb-11">
//           <div className="text-left flex flex-col justify-between md:pl-14 pt-5">
//             <div style={{ color: "#393285" }}>
//               <h1 className="text-4xl md:text-7xl font-bold">
//                 Get Your Job In
//               </h1>
//               <h1 className="text-4xl md:text-7xl font-bold">48 Hours!!</h1>
//               <p className="text-md md:text-lg mt-2 text-black font-semibold">
//                 Log in now and get your job with Hirers.
//               </p>
//             </div>

//             {/* Desktop View: Looking for hiring? Section */}
//             <div className="text-md md:text-lg font-semibold md:block hidden">
//               <p>Looking for hiring?</p>
//               <button
//                 className="text-blue-700"
//                 onClick={() => navigate("/Desktop-3")}
//               >
//                 Click Here
//               </button>
//             </div>
//           </div>

//           {/* Candidate LogIn Section */}
//           <div className="flex justify-center w-full md:w-1/3 md:h-2/3 md:mt-0 mr-20 mt-14 h-72 order-3  md:rounded-none">
//             <div
//               className=" rounded-3xl shadow-lg w-full md:p-12 p-6"
//               style={{ background: "#DFDCF7" }}
//             >
//               <h2 className="text-2xl md:text-3xl font-semibold mb-4">
//                 Candidate LogIn/SignUp
//               </h2>
//               <form
//                 onSubmit={otpSent ? handleVerifyOtp : handleSendOtp}
//                 className="space-y-4"
//               >
//                 <div className="flex space-x-2">
//                   <input
//                     type="text"
//                     value={countryCode}
//                     onChange={(e) => setCountryCode(e.target.value)}
//                     placeholder="+91"
//                     className="border p-2 rounded-lg w-16 font-semibold md:h-14 text-2xl"
//                   />
//                   <input
//                     type="text"
//                     value={phoneNumber}
//                     onChange={(e) => setPhoneNumber(e.target.value)}
//                     placeholder="Mobile number"
//                     className="border p-2 rounded-lg w-full font-semibold md:h-14 text-2xl"
//                   />
//                 </div>
//                 {otpSent && (
//                   <input
//                     type="text"
//                     value={otp}
//                     onChange={(e) => setOtp(e.target.value)}
//                     placeholder="Enter OTP"
//                     className="border p-2 rounded-lg w-full font-semibold md:h-14 text-2xl"
//                   />
//                 )}
//                 <button
//                   type="submit"
//                   className="text-white p-2 w-full rounded-lg md:h-16 text-3xl"
//                   style={{ background: "#393285" }}
//                 >
//                   {otpSent ? "Verify OTP" : "Send OTP"}
//                 </button>
//               </form>
//               <p className="text-center mt-4 font-semibold md:text-xl">
//                 Click here to login using Google/FB account
//               </p>
//               <div className="flex justify-center space-x-2 mt-2">
//                 <img
//                   src="/google.png"
//                   alt="Google"
//                   className="w-8"
//                   onClick={handleGoogleSignIn}
//                 />
//                 <img
//                   src="/facebook.png"
//                   alt="Facebook"
//                   className="w-8"
//                   onClick={handleFacebookSignIn}
//                 />
//               </div>
//             </div>
//           </div>
//         </div>

//         {/* Mobile View: Looking for hiring? Section */}
//         <div className="text-xl md:hidden hidden font-semibold">
//           <p>Looking for hiring?</p>
//           <button
//             className="text-blue-700"
//             onClick={() => navigate("/Desktop-3")}
//           >
//             Click Here
//           </button>
//         </div>

//         {/* Selected Candidates Section */}
//         <div className="text-center mb-6 ">
//           <h2 className="text-2xl md:text-3xl font-bold text-purple-800">
//             Our selected candidates
//           </h2>
//         </div>

//         {/* Cards for Job Seekers */}
//         <div className="grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-10 pl-4 mt-10 hidden md:grid">
//           {jobSeekersData.map((seeker, index) => (
//             <div className="relative" key={index}>
//               {/* Card with text content */}
//               <div
//                 className="text-xs space-y-4 text-right h-52 w-full pr-3 mt-10 rounded-3xl font-semibold md:text-2xl flex flex-col justify-start pt-8 relative"
//                 style={{
//                   background: "#DFDCF7",
//                 }}
//               >
//                 <p>{seeker.name}</p>
//                 <p>{seeker.company}</p>
//                 <p>{seeker.location}</p>
//               </div>

//               {/* Image aligned to the left of the text */}
//               <img
//                 src={seeker.image}
//                 alt="jobseeker"
//                 className="h-72 md:h-72 w-40 md:w-64 transform absolute -top-12 -left-16"
//               />
//             </div>
//           ))}
//         </div>
//       </div>

//       <div className="md:hidden">
//         <HorizontalScroll data="Companies that hire from us" />
//       </div>
//     </div>
//   );
// };

// export default CompanySection;

import React, { useEffect, useState } from "react";
import HorizontalScroll from "../Desktop1/companys_scroll";
import { auth, googleProvider } from "../firebase";
import { signInWithPopup } from "firebase/auth";
import { useNavigate } from "react-router-dom";
// import Navbar2 from "../Sections/Navbar-3&5/Navbar2";
import axios from "axios";
// import CryptoJS from "crypto-js";
import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css"; // Import CSS for Toastify
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
// import HashLoader from "react-spinners/HashLoader";

const CompanySection = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const type = localStorage.getItem("type");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // console.log("Token:", token);
    if (token && type === "users") {
      navigate("/Frame-10");
    }
  }, [token, type, navigate]);

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      // testing code
      const idToke = await user.getIdTokenResult();
      if (!idToke.claims.role) {
        // If the user is new (no role), set their role
        // console.log("new user", process.env.REACT_APP_TYPE_EMPLOYER);
        await setRoleOnBackend(user.uid, process.env.REACT_APP_TYPE_EMPLOYER); // Set role as "employee" for new users
        await getTokenFromServer(user.uid, process.env.REACT_APP_TYPE_EMPLOYER); // Reuse the external function
        toast.success("Google Sign-In successful!");
        navigate("/Frame-10");
      } else {
        const role = idToke.claims.role;
        if (role === process.env.REACT_APP_TYPE_EMPLOYER) {
          await getTokenFromServer(
            user.uid,
            process.env.REACT_APP_TYPE_EMPLOYER
          );
          toast.success("Google Sign-In successful!");
          navigate("/Frame-10");
        } else if (
          role === process.env.REACT_APP_TYPE_EMPLOYEE ||
          role === process.env.REACT_APP_TYPE_ADMIN
        ) {
          toast.error("Access Denied for this email");
        } else {
          toast.error("Invalid role detected. Please contact support.");
        }
      }
    } catch (error) {
      if (error.code === "auth/popup-closed-by-user") {
        toast.warn("Google Sign-In was closed by the user.");
      } else {
        toast.error("Google Sign-In failed. Please try again.");
      }
    } finally {
    }
  };

  const getTokenFromServer = async (uid, role) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_PORT}/generateToken`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ uid, type: role }), // Pass UID and role as necessary data
        }
      );

      if (!response.ok) {
        throw new Error("Failed to retrieve token");
      }
      const data = await response.json();
      localStorage.setItem("token", data.token);
    } catch (error) {
      console.error("Error fetching token:", error);
      throw error; // Rethrow to be caught in the outer try-catch
    }
  };

  const setRoleOnBackend = async (uid, role) => {
    try {
      await fetch(`${process.env.REACT_APP_PORT}/setRole`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ uid, role }),
      });
    } catch (error) {
      console.error("Error setting role:", error);
    }
  };

  // const handleFacebookSignIn = async () => {
  //   try {
  //     // console.log("Facebook Sign-In initiated");
  //     const result = await signInWithPopup(auth, facebookProvider);
  //     const user = result.user;
  //     // console.log("User Info from Facebook:", user);
  //     const response = await fetch(
  //       `${process.env.REACT_APP_PORT}/generateToken`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({ uid: user.uid, type: "users" }), // Pass UID or any other necessary data
  //       }
  //     );
  //     const data = await response.json();
  //     localStorage.setItem("token", data.token);
  //     // console.log("User Info from Facebook:", user);
  //     toast.success("Facebook Sign-In successful!");
  //   } catch (error) {
  //     console.error("Error during Facebook login:", error.message);
  //     toast.error("Facebook Sign-In failed. Please try again.");
  //   } finally {
  //   }
  // };

  // Ensure this matches the server's key

  const [countryCode, setCountryCode] = useState("+91");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);

  const handleVerifyOtp = async (e) => {
    setLoading(true);
    e.preventDefault();

    if (!otp) {
      toast.error("Please enter the OTP");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PORT}/verify-otp`,
        {
          phoneNumber: countryCode + phoneNumber,
          otp: otp,
        }
      );
      // console.log(response.data);

      if (response.data.message === "OTP verified successfully") {
        toast.success("OTP verified successfully!");
        // const decryptedPhone = decryptPhoneNumber(response.data.phoneNumber);
        // console.log(decryptedPhone);
        const responso = await fetch(
          `${process.env.REACT_APP_PORT}/generateToken`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              uid: response.data.phoneNumber,
              type: "users",
            }), // Pass UID or any other necessary data
          }
        );
        const data = await responso.json();
        localStorage.setItem("token", data.token);
        localStorage.setItem("type", "users");
        navigate("/Frame-10");
      } else {
        toast.error("Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      toast.error("There was an error verifying the OTP.");
    } finally {
      setLoading(false);
    }
  };

  const handleSendOtp = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!phoneNumber || phoneNumber.length !== 10) {
       toast.error("Please enter a valid 10-digit phone number");
           setTimeout(()=> {
             setLoading(false);
           }, 500)
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PORT}/send-otp`,
        {
          phoneNumber: countryCode + phoneNumber,
        }
      );

      if (response.data.message === "OTP sent successfully") {
        toast.success("OTP sent successfully!");
        setOtpSent(true);
      } else {
        toast.error("Failed to send OTP.");
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      toast.error("There was an error sending the OTP.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* full page loading effect */}
      {/* {loading ? (
        <HashLoader
          color="#34a5f3"
          size={60} // Adjust the size as needed
          cssOverride={{
            position: "absolute", // Ensure the loader is positioned relative to the viewport
            top: "50%", // Center vertically
            left: "50%", // Center horizontally
            transform: "translate(-50%, -50%)", // Adjust to truly center the loader
          }}
        />
      ) : ( */}
      <section className="py-10 w-full font-inter">
        {/* ------------ container ---------------- */}

        <div className="xl:w-10/12 xs:w-11/12 w-[95%] mx-auto  flex flex-col ">
          {/* -------- 1st section -------- */}
          <div className="flex xmd:flex-row flex-col  xl:gap-x-8 gap-x-6 gap-y-12 justify-between ">
            {/* ----- left part ---- */}
            <div className="xl:w-[46%] xmd:w-[43%] md:w-[80%] sm:w-[90%] mx-auto flex flex-col gap-y-2 pr-6 xs:pr-0">
              <h1 className="lg:text-[40px] text-3xl font-semibold xl:w-[80%] w-[90%]   sm:leading-[56px] leading-[44px] text-blue-200 ">
                Get Your Job In 48 Hours!!
              </h1>
              <p className="text-lg sm:text-xl font-semibold ">
                Log in now and get your job with Hirers.
              </p>
            </div>

            {/* ------- right part ----- */}
            <div className=" xl:w-[45%] xmd:w-[50%] md:w-[80%] sm:w-[90%]  w-full  mx-auto  bg-light-purple-100 py-6 xl:px-10 sm:px-6 px-4  rounded-[32px] flex flex-col gap-y-6 items-center ">
              <h2 className="xl:text-xl xs:text-lg font-semibold text-center">
                Candidate LogIn/SignUp
              </h2>
              <form
                onSubmit={otpSent ? handleVerifyOtp : handleSendOtp}
                className="flex flex-col gap-y-4  justify-center items-center "
              >
                <div className=" xs:w-full w-[50%] flex  dxs:flex-nowrap flex-wrap xl:gap-x-8 xsm:gap-x-4 gap-x-2 justify-center  items-center  sm:text-lg text-base">
                  <input
                    type="text"
                    value={countryCode}
                    onChange={(e) => setCountryCode(e.target.value)}
                    placeholder="+91"
                    className="py-3 px-2 sm:w-[60px] xsm:w-[48px] w-[44px] rounded-lg text-black placeholder-black font-medium"
                  />
                  <input
                    type="text"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    placeholder="Mobile Number"
                    className="py-3 px-4 rounded-lg placeholder-black text-black font-medium"
                  />
                </div>
                {otpSent && (
                  <div className="xs:w-full w-[100%] flex justify-end items-center xl:gap-x-8 xsm:gap-x-4 gap-x-2 ">
                    <input
                      type="text"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      placeholder="Enter OTP"
                      className="py-3 px-4 w-[100%] rounded-lg placeholder-black text-black font-medium "
                    />
                  </div>
                )}

                <button
                  type="submit"
                  className=" xs:w-full w-[50%] bg-blue-200 text-white flex items-center justify-center py-2 rounded-lg xl:text-xl xsm:text-lg text-base transition-all duration-300 hover:bg-blue-300 hover:scale-105 px-2 "
                  style={{ background: "#393285" }}
                >
                  {/* btn laoding effect */}
                  {loading ? (
                    <p className="loader"></p>
                  ) : otpSent ? (
                    "Verify OTP"
                  ) : (
                    "Login"
                  )}
                </button>
              </form>
              <div className="flex flex-col  items-center">
                <p className="text-base font-medium text-center">
                  Click here to login using Google/FB account
                </p>
                <div className="flex gap-x-4 mt-2">
                  <LazyLoadImage
                    effect="blur"
                    src="/google.png"
                    alt="Google"
                    className="sm:w-8 sm:h-8 w-7 h-7 cursor-pointer transition-all duration-300  hover:scale-110"
                    onClick={handleGoogleSignIn}
                  />

                  {/* facebook icon  */}
                  {/* <LazyLoadImage
                      effect="blur"
                      src="/facebook.png"
                      alt="facebook"
                      className="sm:w-8 sm:h-8 w-7 h-7  cursor-pointer transition-all duration-300  hover:scale-110"
                      onClick={handleFacebookSignIn}
                    /> */}
                </div>
              </div>
            </div>
          </div>

          {/* Mobile View: Looking for hiring? Section */}
          <div className=" font-semibold text-base mt-6 lg:mt-0 mb-3">
            <p className="text-black">Looking for a hiring?</p>
            <button
              className="text-blue-200 transition-all duration-300 hover:text-blue-300 hover:scale-105"
              onClick={() => navigate("/Desktop-3")}
            >
              Click Here
            </button>
          </div>

          {/* ------- 3rd section --- */}

          {/* <div className="hidden xmd:flex flex-col gap-y-12">

            <h2 className="text-center font-semibold text-blue-200 text-3xl">Companies that hire from us</h2>
            
            <div className="grid xl:grid-cols-7 lg:grid-cols-6 grid-cols-5 justify-items-center  gap-x-4 gap-y-12">
              {companies.map((logo, index) => (
                <div key={index} className="w-32 h-32">
                  <LazyLoadImage
                    effect="blur"
                    src={`/companies logo/${logo}`}
                    alt="company-logo"
                    className="w-full h-full object-contain rounded-full"
                  />
                </div>
              ))}
            </div>
          </div>

               {
                companies.map((logo , index)=> (

                  <div
                  key={index}
                   className="w-32 h-32">

                    <img
                  src={`/companies logo/${logo}`}
                  alt="company-logo"
                  className="w-full h-full object-contain rounded-full"
                    />

                  </div>
                ))
               }

            </div>

          </div> */}
        </div>
        <HorizontalScroll data="Company that hire from us" className="w-full" />
      </section>
      {/* )} */}
    </>
  );
};

export default CompanySection;
