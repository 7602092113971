import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import HashLoader from "react-spinners/HashLoader";
import axios from "axios";
import { toast } from "react-toastify";
import Navemployee from "../Sections/Navbar-3&5/Navbar_employee";
function Frame12() {
  const t1 = localStorage.getItem("token");

  const location = useLocation();

  const navigate = useNavigate();
  const { jobId } = useParams();
  const [jobData, setJobData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [allowRender, setAllowRender] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [appliedJob, setAppliedJob] = useState(
    location.state
      ? location.state.alreadyApply === "true"
        ? true
        : false
      : false
  );

  // const [error, setError] = useState(null);

  // const state = location.state ? location.state : "";
  // console.log(location.state)
  // console.log(state);

  // useEffect(() => {
  //   if (location.state) {
  //     if (isApplied !== location.state.alreadyApply) { // ✅ Only update if different
  //       setIsApplied(location.state.alreadyApply);
  //     }
  //   } else {
  //     if (isApplied !== false) { // ✅ Avoid unnecessary re-renders
  //       setIsApplied(false);
  //     }
  //   }

  //   console.log(location.state.alreadyApply)
  // }, []);

  // console.log("Current state.alreadyApply:", state.alreadyApply);

  const handledoc = (jobId) => {
    // setIsApplied(true);
    navigate(`/Section-2/${jobId}`); // Navigate to Section3 with jobId
  };

  // const jobData = {
  //   jobTitle: "Software Engineer",
  //   companyName: "TechCorp",
  //   companyAddress: "123 Silicon Valley",
  //   jobLocation: "San Francisco, CA",
  //   salary: "$120,000",
  //   educationQualification: "Bachelor's in Computer Science and dbh dhuysb",
  //   experienceLevel: "3-5 years",
  //   communicationSkill: "Excellent",
  //   Description:
  //     "Develop and maintain web applications using modern frameworks. ",
  //   Gender: "Any",
  //   similarJobs: [
  //     "Frontend Developer - San Francisco, CA",
  //     "Backend Developer - Seattle, WA",
  //   ],
  // };

  useEffect(() => {
    const fetchJobData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_PORT}/api/jobs/${jobId}`,
          {
            headers: {
              Authorization: `Bearer ${t1}`,
            },
          }
        );

        setAllowRender(true);
        setJobData({ ...response.data, id: jobId }); // Set jobData without modifying it directly
      } catch (err) {
        if (!toast.isActive("invalid-credentials-toast")) {
          toast.error("Invalid Credentials", {
            toastId: "invalid-credentials-toast",
          });
        }
        navigate("/");
      } finally {
        setLoading(false);
      }
    };

    fetchJobData();
  }, [jobId, t1, navigate]); // ✅ Removed `jobData`

  if (!allowRender) {
    return null;
  }

  // console.log(location.state)

  // console.log("IsAPPPPPP: " , isApplied);

  return (
    <>
      <div className="h-full w-full ">
        {loading ? (
          <HashLoader
            color="#34a5f3"
            size={60} // Adjust the size as needed
            cssOverride={{
              position: "absolute", // Ensure the loader is positioned relative to the viewport
              top: "50%", // Center vertically
              left: "50%", // Center horizontally
              transform: "translate(-50%, -50%)", // Adjust to truly center the loader
            }}
            loading={true}
          />
        ) : (
          <div className="h-full w-full  ">
            <Navemployee />
            <div className="w-full bg-white flex flex-col font-ABeeZee">
              {/* Header Section */}
              <div className="w-full sm:text-white p-3 flex sm:flex-row sm:bg-primary-blue sm:mt-0 mt-2">
                {/* Company Logo (Image) */}
                <div className="w-12 h-12 sm:hidden flex justify-center items-center rounded-full overflow-hidden border">
                  <img
                    src="/profile.jpg"
                    alt={`${jobData.companyName} logo`}
                    className="w-full h-full object-cover sm:rounded-full"
                  />
                </div>

                {/* Job Information */}
                <div className="w-3/4 sm:w-3/4 flex flex-col font-abeezee ml-3 sm:ml-8 sm:text-lg text-base sm:font-normal font-medium">
                  <h2 className="text-lg sm:text-2xl md:text-3xl sm:mb-2 ">
                    {jobData.jobTitle}
                  </h2>
                  <p className="mb-2 text-secondary-blue sm:text-white">
                    {jobData.companyName}
                  </p>

                  {/* Address and Location with Small Rounded Images */}
                  <div className="w-full flex items-center mb-2 xs:mt-3 sm:mt-0 ">
                    <div className="w-9 h-9 sm:hidden flex justify-center items-center mr-3">
                      <img
                        src="/location.png" // Replace with appropriate icon/image
                        alt="Location Icon"
                        className="w-full h-90% object-cover "
                      />
                    </div>
                    <p className="sm:mb-2 text-base sm:text-lg">
                      {jobData.organizationAddress}, {jobData.jobLocation}
                    </p>
                  </div>

                  {/* Salary with Small Rounded Icon */}
                  <div className="w-full flex items-center mb-2">
                    <div className="w-8 h-8  sm:hidden flex justify-center items-center mr-3">
                      <img
                        src="/salary_icon.png" // Replace with appropriate icon/image
                        alt="Salary Icon"
                        className="w-full h-full object-cover "
                      />
                    </div>
                    <p className="text-md sm:text-xl">{jobData.salary}</p>
                  </div>

                  <button
                    className="w-36 bg-white text-black mt-2 py-[6px] px-2 hidden sm:block rounded-lg text-base"
                    onClick={() => handledoc(jobData.id)}
                  >
                    Category
                  </button>
                </div>

                {/* Apply Now Button */}
                <div className="sm:flex items-start justify-end w-full mt-4 sm:mt-0 hidden sm:mr-8">
                  <button
                    disabled={appliedJob}
                    className={` text-secondary-blue py-[6px] px-6 rounded-xl text-base font-semibold
                     ${appliedJob ? "bg-yellow-100" : "bg-white"} `}
                    onClick={() => {
                      // console.log("Button Clicked. Current isApplied:", isApplied);
                      handledoc(jobData.id);
                    }}
                  >
                    {appliedJob ? "Already Applied" : "Apply Now"}
                  </button>
                </div>
              </div>

              <div className=" bg-blue-50 p-3 rounded-2xl shadow-md  flex  justify-between sm:hidden mt-4 gap-2 m-2 text-sm">
                <a
                  href="#job-details"
                  className="px-4 py-1 bg-white text-black rounded-2xl "
                >
                  Job Details
                </a>
                <a
                  href="#job-desc"
                  className="px-4 py-1 bg-white text-black rounded-2xl "
                >
                  Job Description
                </a>
                <a
                  href="#similar-jobs"
                  className="px-4 py-1  bg-white text-black rounded-2xl"
                >
                  Similar Jobs
                </a>
              </div>

              <div className="p-4 flex-1 md:flex-2 md:px-8 md:py-6 flex flex-col justify-around font-abeezee">
                <div className="flex flex-col md:flex-row justify-between  ">
                  <div
                    id="job-details"
                    className="flex-1  mb-6  sm:bg-transparent  rounded-3xl m-2 sm:ml-10 bg-blue-10 sm:bg-white sm:px-0 px-2"
                  >
                    <div className=" w-full sm:mt-0 sm:mb-0 mt-2 mb-2">
                      <h3 className=" sm:text-3xl text-xl  font-abeezee text-center sm:text-left font-semibold sm:font-normal">
                        Job Details
                      </h3>
                    </div>

                    <div className="w-full  grid sm:grid-cols-2 gap-2  md:text-sm mt-2 sm:w-[65vw]">
                      <div className="w-full flex flex-col ">
                        <div className="sm:m-2 mt-2 sm:text-lg sm:text-black-10 text-blue-800 text-lg sm:block flex justify-start sm:ml-0 ml-8">
                          <p className="">Education</p>
                        </div>
                        <div className="sm:m-2 text-base sm:text-xl sm:block flex justify-start sm:ml-0 ml-8 ">
                          <p className="">
                            <span className="font-medium">
                              {Array.isArray(jobData.educationQualification)
                                ? jobData.educationQualification.join(", ")
                                : jobData.educationQualification}
                            </span>
                            {/* {jobData.educationQualification} */}
                          </p>
                        </div>{" "}
                        {/* Updated */}
                        <div className="sm:m-2 mt-4 sm:text-lg sm:text-black-10 text-blue-800 text-lg sm:block flex justify-start sm:ml-0 ml-8 sm:mt-0">
                          <p className="">Experience</p>
                        </div>
                        <div className="sm:m-2 text-base sm:text-xl sm:block flex justify-start sm:ml-0 ml-8 ">
                          <p className="">{jobData.experienceLevel}</p>
                        </div>{" "}
                        <div className="sm:m-2 mt-4 sm:text-lg sm:text-black-10 text-blue-800 text-lg sm:block flex justify-start sm:ml-0 ml-8 sm:mt-0">
                          <p className=""> Address</p>
                        </div>
                        <div className="sm:m-2 text-base sm:text-xl sm:block flex justify-start sm:ml-0 ml-8 ">
                          <p className="">
                            {jobData.organizationAddress},{jobData.jobLocation}
                          </p>
                        </div>{" "}
                        <div className="sm:m-2 mt-4 sm:text-lg sm:text-black-10 text-blue-800 text-lg sm:block flex justify-start sm:ml-0 ml-8 sm:mt-0">
                          <p className=""> Communication Skills</p>
                        </div>
                        <div className="sm:m-2 text-lg sm:text-xl sm:block flex justify-start sm:ml-0 ml-8 sm:mb-0 mb-6 ">
                          <p className="">
                            {" "}
                            <span className="font-medium">
                              {Array.isArray(jobData.communicationSkill)
                                ? jobData.communicationSkill.join(", ")
                                : jobData.communicationSkill}
                            </span>{" "}
                            {/* {jobData.communicationSkill} */}
                          </p>
                        </div>{" "}
                        {/* Updated */}
                      </div>
                      <div className="">
                        {/* <div className="sm:m-2 mt-4 sm:text-lg sm:text-black-10 text-blue-800 text-lg sm:block flex justify-start sm:ml-0 ml-8 sm:mt-0">
                          <p className=""> Gender</p>
                        </div>
                        <div className="sm:m-2 text-lg sm:text-xl sm:block flex justify-start sm:ml-0 ml-8 ">
                          <p className="">
                            {jobData.Gender || "Not mentioned"}
                          </p>
                        </div>{" "} */}
                        {/* Ensure this key exists */}
                        <div className="sm:m-2 mt-4 sm:text-lg sm:text-black-10 text-blue-800 text-lg sm:block flex justify-start sm:ml-0 ml-8 sm:mt-0">
                          <p className=""> Interview Details</p>
                        </div>
                        <div className="sm:m-2 text-lg sm:text-xl sm:block flex justify-start sm:ml-0 ml-8 sm:mb-0 mb-6 ">
                          {/* <p className="">
                            {" "}
                            {jobData.interviewTiming},{jobData.interviewProcess}
                            ,{jobData.interviewLocation},{jobData.interviewCity}
                            &nbsp;{jobData.interviewState}&nbsp;
                            {jobData.interviewPincode}
                          </p> */}
                          <p className="mt-2">
                            Timing:{" "}
                            <span className="font-medium">
                              {jobData.interviewTiming}
                            </span>
                            <br />
                            Process:{" "}
                            <span className="font-medium">
                              {Array.isArray(jobData.interviewProcess)
                                ? jobData.interviewProcess.join(", ")
                                : jobData.interviewProcess}
                            </span>
                            <br />
                            Location:{" "}
                            {jobData.interviewProcess === "offline" ? (
                              <span className="font-medium">
                                {jobData.interviewLocation},{" "}
                                {jobData.interviewCity},{" "}
                                {jobData.interviewState},{" "}
                                {jobData.interviewPincode}
                              </span>
                            ) : (
                              <span></span>
                            )}
                          </p>
                        </div>{" "}
                        <div className="sm:m-2 mt-4 sm:text-lg sm:text-black-10 text-blue-800 text-lg sm:block flex justify-start sm:ml-0 ml-8 sm:mt-0">
                          <p className=""> Work Details</p>
                        </div>{" "}
                        <div className="sm:m-2 text-lg sm:text-xl sm:block flex justify-start sm:ml-0 ml-8 sm:mb-0 mb-6 ">
                          <p className="mt-2">
                            Type:{" "}
                            <span className="font-medium">
                              {Array.isArray(jobData.workType)
                                ? jobData.workType.join(", ")
                                : jobData.workType}
                            </span>
                            <br />
                            Working Hours:{" "}
                            <span className="font-medium">
                              {jobData.workingHours}
                            </span>
                          </p>
                        </div>
                        {/* Updated */}
                      </div>
                    </div>
                  </div>

                  <div
                    id="job-desc"
                    className=" m-2 block sm:hidden  font-abeezee text-center bg-blue-10 rounded-xl"
                  >
                    <h3 className="  text-xl font-semibold mt-2">
                      Job Description
                    </h3>
                    <p className="text-base mt-2 mb-4 text-blue-800">
                      {jobData.jobDescription || "No description available."}
                    </p>
                  </div>
                  <div className="bg-white p-4 rounded-lg flex flex-row justify-between items-center  sm:hidden ">
                    <button className="bg-primary-blue text-white py-2 px-4 rounded-xl hover:bg-blue-600 ">
                      Share
                    </button>
                    <button
                      className="bg-primary-blue  text-white py-2 px-6 rounded-xl hover:bg-blue-600 sm:ml-28"
                      onClick={() => handledoc(jobData.id)}
                    >
                      Apply Now
                    </button>
                  </div>
                  <div id="similar-jobs" className=" flex-1">
                    <h3 className="md:text-2xl text-xl text-center font-abeezee mt-4">
                      Similar Jobs
                    </h3>
                    <div className="mt-2 text-xs md:text-sm text-gray-500 space-y-2 text-center">
                      <div>
                        {/*  */}
                        <button className="  text-white px-6 py-3 rounded-3xl text-lg">
                          {jobData.similarJobs?.length > 0 ? (
                            jobData.similarJobs.map((job, index) => (
                              <div key={index} className="flex justify-between">
                                <button className=" w-52 md:w-60 relative text-start text-black-10 px-4 py-2 rounded-3xl  focus:outline-none focus:ring-2  text-base sm:text-xl  overflow-hidden truncate mb-1">
                                  {job}
                                  <span className="absolute right-2">&gt;</span>
                                </button>
                              </div>
                            ))
                          ) : (
                            <div className="bg-secondary-blue px-4 py-2 rounded-xl text-base">
                              No similar jobs found
                            </div>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="hidden sm:block ">
                  <h3 className=" sm:text-3xl text-xl sm:ml-1 ml-28">
                    Job Description
                  </h3>
                  <p className="mt-2 text-lg sm:text-xl text-black-10 ml-5">
                    {jobData.jobDescription || "No description available."}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Frame12;
