import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
// import { useParams } from "react-router-dom";
import Navemployee from "../Sections/Navbar-3&5/navbar1";
import axios from "axios";
import Swal from "sweetalert2";
import HashLoader from "react-spinners/HashLoader";
import { toast } from "react-toastify";
// import { useForm } from "react-hook-form";

const Section7 = () => {
  // const token = localStorage.getItem("uid");
  const navigate = useNavigate();
  const [loadin, setLoadin] = useState(false); // State to track loading //change false for styling -- Musha
  // const [allowRender, setAllowRender] = useState(false);
  // const {
  //   register,
  //   handleSubmit,
  //   reset,
  //   formState: { errors },
  // } = useForm();

  const [profileImageURL, setProfileImageURL] = useState("/profile.jpg"); // Default fallback image
  const [newUser, setNewUser] = useState(true);
  const [headingLoader, setHeaderLoader] = useState(true);

  function handleSubmitButton() {
    Swal.fire({
      title: "Success",
      text: "Your Profile has been updated",

      icon: "success",
      confirmButtonText: "ok",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/Frame-18");
      }
    });
  }
  let timerInterval;
  let loading = false;
  function handleProgress() {
    Swal.fire({
      title: "Profile has been updating .....",
      html: "",
      timerProgressBar: loading,
      didOpen: () => {
        Swal.showLoading();
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        // console.log("I was closed by the timer");
      }
    });
  }
  const t1 = localStorage.getItem("token");
  const [formData, setFormData] = useState({
    // uid: token,
    name: "",
    profile: null,
    email: "",
    gender: "",
    mobileNumber: "",
    companyAddress: "",
    selectedState: "",
    selectedCity: "",
    designation: "",
    pincode: "",
    // language: "English",
  });
  useEffect(() => {
    setHeaderLoader(true);
    const fetchProfileData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_PORT}/api/updateprofiledata`,
          { type: process.env.REACT_APP_TYPE_EMPLOYEE },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${t1}`,
            },
          }
        );
        const profileData = response.data;
        // console.log(profileData);
        if (!response.data.message) {
          setNewUser(false);
          setFormData(profileData); // Update the form data state

          if (profileData.profile) {
            setProfileImageURL(profileData.profile);
          }
          // setAllowRender(true); // Allow the page to render if data is valid
        }
      } catch (err) {
        // localStorage.removeItem("token");
        toast.dismiss();
        if (!toast.isActive("invalid-credentials-toast")) {
          toast.error("Invalid Credentials", {
            toastId: "invalid-credentials-toast",
          });
        }
        navigate("/");
      } finally {
        setLoadin(false); // Set loading to false when fetch completes
        setHeaderLoader(false);
      }
    };

    if (t1) {
      fetchProfileData(); // Call the function once the token is available
    } else {
      toast.warn("Please login to continue");
      navigate("/Desktop-3");
      setLoadin(false);
    }
  }, [t1, navigate]);

  // if (!allowRender) {
  //   return null; // Don't render anything until checks are done
  // }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Create a local URL for the selected file
      const imageUrl = URL.createObjectURL(file);
      // console.log("Image URL:", imageUrl);
      setProfileImageURL(imageUrl);
      setFormData({
        ...formData,
        [event.target.name]: event.target.files[0], // Only one file
      });
    }
  };

  const validateForm = () => {
    const {
      // profile,
      name,
      email,
      gender,
      mobileNumber,
      companyAddress,
      designation,
      selectedState,
      selectedCity,
      pincode,
    } = formData;

    // if (!profile) return "Profile is required";
    if (!name) return "Name is required";
    if (!email || !/\S+@\S+\.\S+/.test(email)) return "Valid email is required";
    if (!gender) return "Gender is required";
    if (!mobileNumber || !/^\d{10}$/.test(mobileNumber))
      return "Valid 10-digit number is ";
    if (!companyAddress) return "Address is required";
    if (!designation) return "Designation is required";
    if (!selectedState) return "State is required";
    if (!selectedCity) return "City is required";
    if (!pincode || !/^\d{6}$/.test(pincode))
      return "Valid Pincode is required";

    return null; // No errors
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();

    const errors = validateForm();
    if (errors) {
      toast.warn(errors);
      return;
    }
    const formDataToSend = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (value !== null) {
        formDataToSend.append(key, value);
      }
    });
    formDataToSend.append("type", process.env.REACT_APP_TYPE_EMPLOYEE);
    loading = true;
    handleProgress();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PORT}/api/upload`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${t1}`,
          },
        }
      );
      loading = false;
      handleSubmitButton();
      if (response.data.profileUrl) {
        setProfileImageURL(response.data.profileUrl);
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
    }
  };

  const states = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli and Daman and Diu",
    "Delhi",
    "Jammu and Kashmir",
    "Ladakh",
    "Lakshadweep",
    "Puducherry",
  ];

  return (
    <>
      <div>
        {loadin || headingLoader ? (
          <HashLoader
            color="#34a5f3"
            size={60} // Adjust the size as needed
            cssOverride={{
              position: "absolute", // Ensure the loader is positioned relative to the viewport
              top: "50%", // Center vertically
              left: "50%", // Center horizontally
              transform: "translate(-50%, -50%)", // Adjust to truly center the loader
            }}
            loading={true}
          />
        ) : (
          <div>
            <Navemployee />
            <section className="py-12">
              <div className="w-11/12 lg:w-10/12 xl:w-9/12 mx-auto   flex flex-col xs:gap-y-8 gap-y-4">
                {/* ---- heading ---- */}

                <h1 className="text-center md:text-4xl xs:text-3xl text-2xl font-bold text-blue-200">
                  {newUser ? "Create Your Profile" : "Edit/Manage Your Profile"}
                </h1>

                {/* ----- form ----- */}

                <form
                  onSubmit={handleSubmitForm}
                  className="flex flex-col gap-y-6 md:flex-row md:justify-between"
                >
                  {/* ----- profile ----- */}

                  <div className=" md:w-max flex justify-center items-center md:items-start">
                    <img
                      src={profileImageURL}
                      alt="Company Logo"
                      name="profile"
                      onClick={() =>
                        document.getElementById("fileInput").click()
                      } // Trigger input click on image click
                      className="w-24 h-24 xs:w-28 xs:h-28 sm:w-32 sm:h-32 rounded-full object-cover"
                    />

                    <input
                      id="fileInput"
                      type="file"
                      name="profile"
                      //
                      onChange={handleFileChange}
                      style={{ display: "none" }} // Hide the file input
                    />
                  </div>

                  <div className="md:w-[86%] xs:w-[80%] w-[85%] mx-auto flex flex-col gap-y-6 xmd:text-xl sm:text-lg xs:text-base text-sm font-abeezee">
                    <div className="grid sm:grid-cols-3 grid-cols-1 xl:gap-x-12 lg:gap-x-8 gap-x-4 gap-y-6">
                      {/* ---- your name ---- */}

                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Your Full name*"
                        className="bg-light-purple-100 placeholder-black rounded-[30px] py-2 w-full px-4 outline-none focus:outline-blue-100 "
                      />

                      {/* ----- email ---- */}

                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Email ID*"
                        className="bg-light-purple-100 placeholder-black rounded-[30px] py-2 w-full px-4 outline-none focus:outline-blue-100 "
                      />

                      {/* ------ gender ----- */}

                      <select
                        name="gender"
                        value={formData.gender}
                        onChange={handleChange}
                        className="bg-light-purple-100 placeholder-black rounded-[30px] py-2 w-full px-4 outline-none focus:outline-blue-100"
                      >
                        <option value="" disabled>
                          Select Gender*
                        </option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                      </select>
                    </div>

                    <div className="grid sm:grid-cols-3 grid-cols-1 xl:gap-x-12 lg:gap-x-8 gap-x-4 gap-y-6">
                      {/* ------ mobile number ----- */}
                      <input
                        type="text"
                        name="mobileNumber"
                        value={formData.mobileNumber}
                        onChange={handleChange}
                        placeholder="Mobile Number*"
                        className="bg-light-purple-100 placeholder-black rounded-[30px] py-2 w-full px-4 outline-none focus:outline-blue-100 "
                        minLength="10"
                        maxLength="10"
                        pattern="\d{10}"
                      />

                      {/* ----- company address ----- */}

                      <input
                        type="text"
                        name="companyAddress"
                        value={formData.companyAddress}
                        onChange={handleChange}
                        placeholder="Company Address*"
                        className="sm:col-span-2 bg-light-purple-100 placeholder-black rounded-[30px] py-2 w-full px-4 outline-none focus:outline-blue-100"
                      />
                    </div>

                    <div className="grid xs:grid-cols-3 grid-cols-1 xl:gap-x-12 lg:gap-x-8 gap-x-4 gap-y-6">
                      {/* ------ desgination ----- */}

                      <input
                        type="text"
                        name="designation"
                        value={formData.designation}
                        onChange={handleChange}
                        placeholder="Designation*"
                        className="bg-light-purple-100 placeholder-black rounded-[30px] py-2 w-full px-4 outline-none focus:outline-blue-100"
                      />

                      {/* ----- states ----- */}

                      <select
                        name="selectedState"
                        value={formData.selectedState}
                        onChange={handleChange}
                        className="bg-light-purple-100 placeholder-black rounded-[30px] py-2 w-full px-4 outline-none focus:outline-blue-100"
                      >
                        <option value="" key={0}>
                          State*
                        </option>
                        {states.map((state, index) => (
                          <option key={index} value={state}>
                            {state}
                          </option>
                        ))}
                      </select>

                      {/* ------- city ------ */}

                      <input
                        type="text"
                        name="selectedCity"
                        value={formData.selectedCity}
                        onChange={handleChange}
                        placeholder="City*"
                        className="bg-light-purple-100 placeholder-black rounded-[30px] py-2 w-full px-4 outline-none focus:outline-blue-100"
                      />
                    </div>

                    <div className="flex  xs:justify-center ">
                      <input
                        type="text"
                        name="pincode"
                        value={formData.pincode}
                        onChange={handleChange}
                        placeholder="Pincode*"
                        className="bg-light-purple-100 placeholder-black rounded-[30px] py-2  px-4 outline-none focus:outline-blue-100 w-full xs:w-max"
                      />
                    </div>

                    {/* ---- submit / edit button --- */}
                    <div className="font-inter flex justify-end">
                      <button
                        type="submit"
                        className="bg-blue-200 xs:mt-0 mt-2 text-white px-16 py-2 rounded-[140px] font-semibold transition-all duuration-300 cursor-pointer hover:scale-105"
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </div>
        )}
      </div>
    </>
  );
};

export default Section7;
