import React from "react";

// Desktop 3 component
import Desktop3 from "./components/Desktop-3";
import Desktop4 from "./components/Desktop4";

import Frame3 from "./components/Frames/Frame-3";
import Frame10 from "./components/Frames/Frame-10";
import Frame11 from "./components/Frames/Frame-11";
import Frame12 from "./components/Frames/Frame-12";
import Frame18 from "./components/Frames/Frame-18";
import Refund from "./components/Desktop1/refund";
import Terms from "./components/Desktop1/terms_conditions";
import PrivacyPolicy from "./components/Desktop1/PrivacyPolicy";
import PricingPage from "./components/Payment";

import Section2 from "./components/Sections/section2";
import Section3 from "./components/Sections/section3";
import Section4 from "./components/Sections/section4";
import Section5 from "./components/Sections/section5";
import Section6 from "./components/Sections/section6";
import Section7 from "./components/Sections/section7";
import Section8 from "./components/Sections/section8";
// import Contact from "./components/Desktop1/Contact";
import Admin from "./components/Admin";
import NotFound from "./components/UnknowRoute/NotFound";
import { ToastContainer } from "react-toastify";
import CashfreePayment from "./components/Cashfree";
import ContactUs from "./components/Desktop1/ContactUs";
import Payment from "./components/Payment";

// import TestOtp from "./components/TestOtp";

// on going trying to merge section 5 and 51 into one component
// import Section from "./components/section";

// import Contact from "./components/Contact";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Cards2 from "./components/Desktop1/Desktop-1"; // Import the Cards2 component

const App = () => {
  return (
    <Router>
      {/* Task -1 */}
      <Routes>
        <Route path="/Contact" element={<ContactUs />} />
        <Route path="/refund_policy" element={<Refund />} />
        <Route path="/terms_conditions" element={<Terms />} />
        <Route path="/privacy_policy" element={<PrivacyPolicy />} />
        <Route path="/pricing_page" element={<PricingPage />} />
        <Route path="/" element={<Cards2 />} />{" "}
        {/* Cards2 as the main component */}
        <Route path="/Desktop-3" element={<Desktop3 />} />{" "}
        {/* Desktop-3 component */}
        <Route path="/Desktop-4" element={<Desktop4 />} />{" "}
        {/* Desktop-4 component */}
        <Route path="/Frame-10" element={<Frame10 />} />{" "}
        {/* Frame-10 component */}
        <Route path="/Frame-11" element={<Frame11 />} />{" "}
        {/* Frame-11 component */}
        {/* <Route path="/Frame-12" element={<Frame12 />} />{" "} */}
        {/* Frame-12 component */}
        <Route path="/Frame-18" element={<Frame18 />} />{" "}
        {/* Frame-18 component */}
        {/* <Route path="/Section-2" element={<Section2 />} />{" "} */}
        {/* Section-2 component */}
        <Route path="/Section-3" element={<Section3 />} />{" "}
        {/* Section-3 component */}
        <Route path="/Section-4" element={<Section4 />} />{" "}
        {/* Section-4 component */}
        <Route path="/Section-5" element={<Section5 />} />{" "}
        <Route path="/section-5" element={<Section5 />} />{" "}
        <Route path="/Section-6" element={<Section6 />} />{" "}
        <Route path="/Section-7" element={<Section7 />} />{" "}
        <Route path="/Section-8" element={<Section8 />} />
        {/* Section-5 component */}
        <Route path="/Frame-12/:jobId" element={<Frame12 />} />
        <Route path="/Frame-3" element={<Frame3 />} />
        <Route path="/Section-3/:jobId" element={<Section3 />} />
        <Route path="/Section-2/:jobId" element={<Section2 />} />
        <Route path="/payment_gateway" element={<Payment />} />
        <Route path="/Admin" element={<Admin />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/free" element={<CashfreePayment />} />
        {/* Section-5 component */}
        {/* <Route path="/test" element={<TestOtp />} /> */}
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Router>
  );
};

export default App;
