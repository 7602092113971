// import React, { useEffect, useState, useRef } from "react";
// import HorizontalScroll from "./Desktop1/companys_scroll";
// import { auth, googleProvider } from "./firebase";
// import { signInWithPopup } from "firebase/auth";
// import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { LazyLoadImage } from "react-lazy-load-image-component";
// import "react-lazy-load-image-component/src/effects/blur.css";
// import firebase from "../firebase1";

// const Desktop3 = () => {
//   const navigate = useNavigate();
//   const token = localStorage.getItem("token");
//   const type = localStorage.getItem("type");
//   const [load, setLoad] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [cooldown, setCooldown] = useState(0); // Cooldown timer in seconds

//   useEffect(() => {
//     (function () {
//       const originalSetTimeout = window.setTimeout;
//       window.setTimeout = function (fn, delay, ...args) {
//         if (delay === 8000) {
//           delay = 500; // Shorten Firebase's default polling delay to 2000ms
//         }
//         return originalSetTimeout(fn, delay, ...args);
//       };
//     })();
//   }, []);

//   useEffect(() => {
//     if (token && type === "employee") {
//       navigate("/Frame-18");
//     }
//   }, [type, token, navigate]);

//   const handleGoogleSignIn = async () => {
//     try {
//       setLoad(true); // Set loading to true when sign-in process starts
//       const result = await signInWithPopup(auth, googleProvider);
//       const user = result.user;

//       // Testing code
//       const idToken = await user.getIdTokenResult();
//       if (!idToken.claims.role) {
//         await setRoleOnBackend(user.uid, "employee"); // Set role as "employee" for new users
//         await getTokenFromServer(user.uid, "employee"); // Reuse the external function
//         toast.success("Google Sign-In successful!");
//         navigate("/Frame-18");
//       } else {
//         const role = idToken.claims.role;
//         if (role === process.env.REACT_APP_TYPE_EMPLOYEE) {
//           await getTokenFromServer(
//             user.uid,
//             process.env.REACT_APP_TYPE_EMPLOYEE
//           ); // Reuse the external function
//           toast.success("Google Sign-In successful!");
//           navigate("/Frame-18");
//         } else if (
//           role === process.env.REACT_APP_TYPE_EMPLOYEE ||
//           role === process.env.REACT_APP_TYPE_ADMIN
//         ) {
//           toast.error("Access Denied for this email");
//         } else {
//           toast.error("Invalid role detected. Please contact support.", role);
//         }
//         setLoad(false);
//       }
//     } catch (error) {
//       // Detect if the user closed the popup without signing in
//       setLoad(false);
//       if (error.code === "auth/popup-closed-by-user") {
//         toast.info("Sign-In process was canceled by the user.", {
//           toastId: "popup-closed-toast",
//         });
//       } else {
//         // Handle other errors
//         console.error("Error during Google Sign-In: ", error);
//         if (!toast.isActive("google-credentials-toast")) {
//           toast.error("Google Sign-In failed. Please try again.", {
//             toastId: "google-credentials-toast",
//           });
//         }
//       }
//     }
//   };

//   const getTokenFromServer = async (uid, role) => {
//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_PORT}/generateToken`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({ uid, type: role }), // Pass UID and role as necessary data
//         }
//       );

//       if (!response.ok) {
//         throw new Error("Failed to retrieve token");
//       }

//       const data = await response.json();
//       localStorage.setItem("token", data.token);
//     } catch (error) {
//       console.error("Error fetching token:", error);
//       throw error; // Rethrow to be caught in the outer try-catch
//     }
//   };

//   const setRoleOnBackend = async (uid, role) => {
//     try {
//       await fetch(`${process.env.REACT_APP_PORT}/setRole`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ uid, role }),
//       });
//     } catch (error) {
//       console.error("Error setting role:", error);
//     }
//   };

//   const [mobile, setMobile] = useState("");
//   const [otp, setOtp] = useState("");
//   const [otpSent, setOtpSent] = useState(false);
//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     if (name === "mobile") setMobile(value);
//     else if (name === "otp") setOtp(value);
//   };

//   const configureCaptcha = () => {
//     // Initialize reCAPTCHA
//     window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
//       "recaptcha-container",
//       {
//         size: "invisible",
//         callback: (response) => {
//           console.log("reCAPTCHA solved, response:", response);
//         },
//         defaultCountry: "IN",
//       }
//     );

//     // Render the reCAPTCHA widget
//     window.recaptchaVerifier.render().then((widgetId) => {
//       window.recaptchaWidgetId = widgetId;
//       console.log("reCAPTCHA rendered with widget ID:", widgetId);
//     });
//   };

//   const timerRef = useRef(null); // Reference to manage the timer

//   const onSignInSubmit = (e) => {
//     console.log("hlo bdy");
//     if (cooldown > 0) return; // Prevent sending OTP during cooldown

//     setLoading(true);
//     e.preventDefault();

//     if (!mobile || !/^\d{10}$/.test(mobile)) {
//       setLoading(false);
//       return toast.warn("Valid 10 digit number is required");
//     }

//     // Start the cooldown
//     if (cooldown === 0) {
//       setCooldown(30); // Set cooldown time to 30 seconds
//       timerRef.current = setInterval(() => {
//         setCooldown((prev) => {
//           if (prev <= 1) {
//             clearInterval(timerRef.current); // Clear timer when cooldown ends
//             return 0;
//           }
//           return prev - 1; // Decrement cooldown
//         });
//       }, 1000);
//     }

//     configureCaptcha(); // Initialize the reCAPTCHA
//     const phoneNumber = "+91" + mobile;
//     const appVerifier = window.recaptchaVerifier;

//     firebase
//       .auth()
//       .signInWithPhoneNumber(phoneNumber, appVerifier)
//       .then((confirmationResult) => {
//         window.confirmationResult = confirmationResult;
//         toast.warn("OTP sent to your phone.");
//         setOtpSent(true);
//         setLoading(false);
//         setCooldown(0);
//       })
//       .catch((error) => {
//         console.error("Error sending OTP:", error);
//       });

//     setLoading(false);
//   };

//   const onSubmitOTP = async (e) => {
//     setLoading(true); // Start loading before verification
//     e.preventDefault();

//     try {
//       const code = otp;
//       const result = await window.confirmationResult.confirm(code);
//       const user = result.user;
//       const idToken = await user.getIdTokenResult();

//       if (!idToken.claims.role) {
//         await setRoleOnBackend(user.uid, process.env.REACT_APP_TYPE_EMPLOYEE);
//         await getTokenFromServer(user.uid, process.env.REACT_APP_TYPE_EMPLOYEE);
//         toast.success("Mobile Sign-In successful!");
//         navigate("/Frame-18");
//       } else {
//         const role = idToken.claims.role;

//         if (role === process.env.REACT_APP_TYPE_EMPLOYEE) {
//           await getTokenFromServer(
//             user.uid,
//             process.env.REACT_APP_TYPE_EMPLOYEE
//           );
//           toast.success("Google Sign-In successful!");
//           navigate("/Frame-18");
//         } else if (
//           role === process.env.REACT_APP_TYPE_EMPLOYER ||
//           role === process.env.REACT_APP_TYPE_ADMIN
//         ) {
//           toast.error("Access Denied for this Mobile Number");
//         } else {
//           toast.error("Invalid role detected. Please contact support.");
//         }
//       }

//       toast.success("Login Successful");
//     } catch (error) {
//       toast.warn("Invalid OTP, Please try again.");
//       setOtp("");
//     } finally {
//       setLoading(false); // Always stop loading after process ends
//     }
//   };

//   useEffect(() => {
//     return () => {
//       if (timerRef.current) {
//         clearInterval(timerRef.current); // Clear timer on unmount
//       }
//     };
//   }, []);

//   return (
//     <>
//       {/* Full-page loading effect */}
//       {load && (
//         <div className="w-full h-full bg-black bg-opacity-50 fixed top-0 left-0 z-50 flex items-center justify-center">
//           <div
//             style={{
//               width: "60px", // Adjust the width as needed
//               height: "60px", // Adjust the height as needed
//               backgroundImage: `url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><radialGradient id="a11" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)"><stop offset="0" stop-color="%23FFFEDF"></stop><stop offset=".3" stop-color="%23FFFEDF" stop-opacity=".9"></stop><stop offset=".6" stop-color="%23FFFEDF" stop-opacity=".6"></stop><stop offset=".8" stop-color="%23FFFEDF" stop-opacity=".3"></stop><stop offset="1" stop-color="%23FFFEDF" stop-opacity="0"></stop></radialGradient><circle transform-origin="center" fill="none" stroke="url(%23a11)" stroke-width="15" stroke-linecap="round" stroke-dasharray="200 1000" stroke-dashoffset="0" cx="100" cy="100" r="70"><animateTransform type="rotate" attributeName="transform" calcMode="spline" dur="2" values="360;0" keyTimes="0;1" keySplines="0 0 1 1" repeatCount="indefinite"></animateTransform></circle><circle transform-origin="center" fill="none" opacity=".2" stroke="%23FFFEDF" stroke-width="15" stroke-linecap="round" cx="100" cy="100" r="70"></circle></svg>')`,
//               backgroundSize: "contain", // Make sure the background scales properly
//               backgroundRepeat: "no-repeat", // Prevent repetition of the background
//               backgroundPosition: "center", // Center the background image
//               borderRadius: "8px", // Optional: Adds rounded corners to the background (adjust as needed)
//             }}
//           ></div>
//         </div>
//       )}
//       <section className="py-10 w-full  font-inter">
//         {/* ------------ container ---------------- */}

//         <div className="xl:w-10/12 xs:w-11/12 xsm:w-[95%] w-[96%] mx-auto  flex flex-col gap-y-4">
//           {/* -------- 1st section -------- */}

//           <div className="flex xmd:flex-row flex-col  xl:gap-x-8 gap-x-6 gap-y-12 justify-between ">
//             {/* ----- left part ---- */}

//             <div className="xl:w-[46%] xmd:w-[43%] md:w-[80%] sm:w-[90%] mx-auto flex flex-col gap-y-4 pr-6 xs:pr-0">
//               <h1
//                 className=" lg:text-[40px] text-4xl font-semibold xl:w-[80%] w-[90%] 
//                  sm:leading-[56px] leading-[48px] text-blue-200 "
//               >
//                 Powering Your Hiring Success!!
//               </h1>
//               <p className="text-lg sm:text-xl font-semibold ">
//                 Log in now and experience the future of hiring with Hirers.
//               </p>
//             </div>

//             {/* ------- right part ----- */}
//             <div className="xl:w-[45%] xmd:w-[50%] md:w-[80%] sm:w-[90%]  w-full  mx-auto  bg-light-purple-100 py-4 xl:px-10 sm:px-6 px-4  rounded-[32px] flex flex-col gap-y-6 items-center ">
//               <h2 className=" xl:text-xl xs:text-lg font-semibold text-center">
//                 Employer LogIn/SignUp
//               </h2>

//               <form
//                 onSubmit={otpSent ? onSubmitOTP : onSignInSubmit}
//                 className=" flex flex-col gap-y-4 "
//               >
//                 <div className="flex xl:gap-x-8 xsm:gap-x-4 gap-x-1 sm:justify-between justify-center items-center sm:text-lg text-base">
//                   <input
//                     type="text"
//                     value="+91"
//                     placeholder="+91"
//                     className="py-3 px-2 sm:w-[60px] xsm:w-[48px] w-[44px] rounded-lg text-black placeholder-black font-medium"
//                     readOnly
//                   />
//                   <input
//                     type="text"
//                     name="mobile"
//                     value={mobile}
//                     onChange={handleChange}
//                     placeholder="Enter Mobile Number"
//                     className="py-3 px-4 rounded-lg placeholder-black text-black font-medium"
//                   />
//                 </div>
//                 {otpSent && (
//                   <input
//                     type="text"
//                     name="otp"
//                     // required
//                     value={otp}
//                     onChange={handleChange}
//                     placeholder="Enter OTP"
//                     className="py-3 px-4 rounded-lg placeholder-black text-black font-medium"
//                   />
//                 )}

//                 <div className="flex justify-center w-full">
//                   <button
//                     type="submit"
//                     className={`xs:w-full w-[80%] ${
//                       cooldown > 0
//                         ? "bg-gray cursor-not-allowed"
//                         : "bg-blue-200 hover:bg-blue-300 hover:scale-105"
//                     } text-white flex items-center justify-center py-2 rounded-lg xl:text-xl xsm:text-lg text-base transition-all duration-300 px-4`}
//                     disabled={cooldown > 0 || loading} // Disable button during cooldown or loading
//                   >
//                     {loading ? (
//                       <p className="loader"></p>
//                     ) : otpSent ? (
//                       "Verify OTP"
//                     ) : cooldown > 0 ? (
//                       `Retry in ${cooldown}s`
//                     ) : (
//                       "Login"
//                     )}
//                   </button>
//                 </div>
//               </form>

//               <div id="recaptcha-container" className="captcha "></div>

//               <div className="flex flex-col gap-y-3 items-center">
//                 <p className=" text-base font-medium text-center">
//                   Click here to login using Google/FB account
//                 </p>
//                 <div className="flex gap-x-4">
//                   <LazyLoadImage
//                     src="/google.png"
//                     alt="Google"
//                     effect="blur"
//                     className="sm:w-8 sm:h-8 w-7 h-7 cursor-pointer transition-all duration-300  hover:scale-110"
//                     onClick={handleGoogleSignIn}
//                   />
//                 </div>
//               </div>
//             </div>
//           </div>

//           {/* ------- 2nd section ------ */}

//           <div className=" font-semibold text-base  mt-6 lg:mt-0 mb-3 ">
//             <p className="text-black">Looking for a Job?</p>
//             <button
//               className="text-blue-200 transition-all duration-300 hover:text-blue-300 hover:scale-105"
//               onClick={() => navigate("/Frame-11")}
//             >
//               Click Here
//             </button>
//           </div>
//         </div>

//         <HorizontalScroll data="Company that hire from us" className="w-full" />
//       </section>
//     </>
//   );
// };

// export default Desktop3;



// import React, { useEffect, useState } from "react";
// import HorizontalScroll from "./Desktop1/companys_scroll";
// import { auth, googleProvider, facebookProvider } from "./firebase";
// import { signInWithPopup } from "firebase/auth";
// import { useNavigate } from "react-router-dom";
// import Navbar1 from "./Sections/Navbar-3&5/Navbar2";
// // import CryptoJS from "crypto-js";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css"; // Import CSS for Toastify

// import axios from "axios";

// const Desktop3 = () => {
//   const companies = [
//     "Zomato_logo.png",
//     "swiggy.png",
//     "rapido.png",
//     "paytm.png",
//     "Zivame-Logo.png",
//     "samsung.png",
//     "techmahindra.png",
//     "daikin.png",
//     "tp.png",
//     "policybazaar.jpeg",
//     "Bharti_Airtel.png",
//     "webvi.png",
//   ];
//   const navigate = useNavigate();
//   const token = localStorage.getItem("token");
//   const type = localStorage.getItem("type");

//   useEffect(() => {
//     if (token && type === "employee") {
//       navigate("/Frame-18");
//     }
//   }, [type, token, navigate]);

//   // const handleGoogleSignIn = async () => {
//   //   try {
//   //     const result = await signInWithPopup(auth, googleProvider);
//   //     const user = result.user;
//   //     // console.log("Google Sign-In successful: ", user);

//   //     // Store UID and type in localStorage
//   //     localStorage.setItem("type", "employee");

//   //     // Fetch token from server and store in localStorage
//   //     const getTokenFromServer = async () => {
//   //       try {
//   //         const response = await fetch(
//   //           `${process.env.REACT_APP_PORT}/generateToken`,
//   //           {
//   //             method: "POST",
//   //             headers: {
//   //               "Content-Type": "application/json",
//   //             },
//   //             body: JSON.stringify({ uid: user.uid, type: "employee" }), // Pass UID or any other necessary data
//   //           }
//   //         );

//   //         if (!response.ok) {
//   //           throw new Error("Failed to retrieve token");
//   //         }

//   //         const data = await response.json();
//   //         localStorage.setItem("token", data.token);
//   //       } catch (error) {
//   //         console.error("Error fetching token:", error);
//   //         throw error; // Rethrow to be caught in the outer try-catch
//   //       }
//   //     };

//   //     await getTokenFromServer(); // Wait for token retrieval to complete
//   //     toast.success("Google Sign-In successful!");
//   //     navigate("/Frame-18");
//   //   } catch (error) {
//   //     console.error("Error during Google Sign-In: ", error);
//   //     toast.error("Google Sign-In failed. Please try again.");
//   //   }
//   // };

//   const handleGoogleSignIn = async () => {
//     try {
//       const result = await signInWithPopup(auth, googleProvider);
//       const user = result.user;
//       // testing code
//       const idToke = await user.getIdTokenResult();
//       if (!idToke.claims.role) {
//         await setRoleOnBackend(user.uid, "employee"); // Set role as "employee" for new users
//         await getTokenFromServer(user.uid, "employee"); // Reuse the external function
//         toast.success("Google Sign-In successful!");
//         navigate("/Frame-18");
//       } else {
//         const role = idToke.claims.role;
//         console.log("Role detected: ", role);
//         if (role === process.env.REACT_APP_TYPE_EMPLOYEE) {
//           await getTokenFromServer(
//             user.uid,
//             process.env.REACT_APP_TYPE_EMPLOYEE
//           ); // Reuse the external function
//           toast.success("Google Sign-In successful!");
//           navigate("/Frame-18");
//           // Redirect to admin dashboard
//         } else if (
//           role === process.env.REACT_APP_TYPE_EMPLOYEE ||
//           role === process.env.REACT_APP_TYPE_ADMIN
//         ) {
//           toast.error("Access Denied for this email");
//         } else {
//           toast.error("Invalid role detected. Please contact support.", role);
//         }
//       }

//       // Fetch token from server and store in localStorage
//     } catch (error) {
//       console.error("Error during Google Sign-In: ", error);
//       toast.error("Google Sign-In failed. Please try again.");
//     }
//   };

//   const getTokenFromServer = async (uid, role) => {
//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_PORT}/generateToken`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({ uid, type: role }), // Pass UID and role as necessary data
//         }
//       );

//       if (!response.ok) {
//         throw new Error("Failed to retrieve token");
//       }

//       const data = await response.json();
//       localStorage.setItem("token", data.token);
//     } catch (error) {
//       console.error("Error fetching token:", error);
//       throw error; // Rethrow to be caught in the outer try-catch
//     }
//   };

//   const setRoleOnBackend = async (uid, role) => {
//     try {
//       await fetch(`${process.env.REACT_APP_PORT}/setRole`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ uid, role }),
//       });
//     } catch (error) {
//       console.error("Error setting role:", error);
//     }
//   };

//   const handleFacebookSignIn = async () => {
//     try {
//       // console.log("Facebook Sign-In initiated");
//       const result = await signInWithPopup(auth, facebookProvider);
//       const user = result.user;
//       const response = await fetch(
//         `${process.env.REACT_APP_PORT}/generateToken`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({ uid: user.uid, type: "employee" }), // Pass UID or any other necessary data
//         }
//       );
//       const data = await response.json();

//       localStorage.setItem("type", "employee");
//       localStorage.setItem("token", data.token);
//       toast.success("Facebook Sign-In successful!");
//     } catch (error) {
//       console.error("Error during Facebook login:", error.message);
//       toast.error("Facebook Sign-In failed. Please try again.");
//     }
//   };

//   // const secretKey = process.env.REACT_APP_SECRET_KEEY; // Ensure this matches the server's key

//   // function decryptPhoneNumber(encryptedPhoneNumber) {
//   //   const bytes = CryptoJS.AES.decrypt(encryptedPhoneNumber, secretKey);
//   //   return bytes.toString(CryptoJS.enc.Utf8);
//   // }

//   const [countryCode, setCountryCode] = useState("+91");
//   const [phoneNumber, setPhoneNumber] = useState("");
//   const [otp, setOtp] = useState("");
//   const [otpSent, setOtpSent] = useState(false);

//   const handleVerifyOtp = async (e) => {
//     e.preventDefault();

//     if (!otp) {
//       toast.error("Please enter the OTP");
//       return;
//     }

//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_PORT}/verify-otp`,
//         {
//           phoneNumber: countryCode + phoneNumber,
//           otp: otp,
//         }
//       );

//       if (response.data.message === "OTP verified successfully") {
//         const responso = await fetch(
//           `${process.env.REACT_APP_PORT}/generateToken`,
//           {
//             method: "POST",
//             headers: {
//               "Content-Type": "application/json",
//             },
//             body: JSON.stringify({
//               uid: response.data.phoneNumber,
//               type: "employee",
//             }), // Pass UID or any other necessary data
//           }
//         );
//         const data = await responso.json();
//         localStorage.setItem("token", data.token);
//         toast.success("OTP verified successfully!");
//         // const decryptedPhone = decryptPhoneNumber(response.data.phoneNumber);
//         // console.log(decryptedPhone);
//         localStorage.setItem("type", "employee");
//         navigate("/Frame-18");
//       } else {
//         toast.error("Invalid OTP. Please try again.");
//       }
//     } catch (error) {
//       console.error("Error verifying OTP:", error);
//       toast.error("There was an error verifying the OTP.");
//     }
//   };

//   const handleSendOtp = async (e) => {
//     e.preventDefault();

//     if (!phoneNumber || phoneNumber.length !== 10) {
//       toast.error("Please enter a valid 10-digit phone number");
//       return;
//     }

//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_PORT}/send-otp`,
//         {
//           phoneNumber: countryCode + phoneNumber,
//         }
//       );

//       if (response.data.message === "OTP sent successfully") {
//         toast.success("OTP sent successfully!");
//         setOtpSent(true);
//       } else {
//         toast.error("Failed to send OTP.");
//       }
//     } catch (error) {
//       console.error("Error sending OTP:", error);
//       toast.error("There was an error sending the OTP.");
//     }
//   };

//   return (
//     <div>
//       <Navbar1 />
//       <div>
//         {/* <ToastContainer
//         position="top-right"
//         autoClose={3000}
//         hideProgressBar={false}
//         newestOnTop={false}
//         closeOnClick
//         rtl={false}
//         pauseOnFocusLoss
//         draggable
//         pauseOnHover
//         theme="light"
//       /> */}
//         <div
//           className="flex flex-col bg-white mt-2 mx-5 lg:mx-20 h-auto"
//           style={{ fontFamily: "Inter" }}
//         >
//           {/* Login/SignUp Page */}
//           <div className="flex flex-col md:flex-row justify-between ">
//             <div className="text-left flex flex-col justify-between">
//               <div style={{ color: "#393285" }}>
//                 <h1 className="text-5xl md:text-7xl font-semibold">
//                   Powering Your
//                 </h1>
//                 <h1 className="text-5xl md:text-7xl font-semibold">
//                   Hiring Success!!
//                 </h1>
//                 <p className="text-xl md:text-2xl mt-2 text-black font-semibold">
//                   Log in now and experience the future of hiring with Hirers.
//                 </p>
//               </div>

//               <div className="text-xl md:text-2xl font-semibold md:block hidden">
//                 <p>Admin SignIn?</p>
//                 <button
//                   className="text-blue-700"
//                   onClick={() => navigate("/admin")}
//                 >
//                   Click Here
//                 </button>
//               </div>
//             </div>

//             {/* Employer LogIn Section */}
//             <div className="flex justify-center w-full  md:w-1/3 md:h-2/3 md:mt-0 mr-20 mt-8 mb-8 h-72 order-3">
//               <div
//                 className=" rounded-2xl mt-8 shadow-lg w-full md:p-12 p-6"
//                 style={{ background: "#DFDCF7" }}
//               >
//                 <h2 className="text-2xl md:text-3xl font-semibold ">
//                   Employer LogIn/SignUp
//                 </h2>
//                 {/* <form className="space-y-4">  
//                 <div className="flex space-x-2">
//                   <input
//                     type="text"
//                     placeholder="+91"
//                     className="border p-2 rounded-lg w-16 font-semibold md:h-14 text-2xl"
//                   />
//                   <input
//                     type="text"
//                     placeholder="Mobile number"
//                     className="border p-2 rounded-lg w-full font-semibold md:h-14 text-2xl"
//                   />
//                 </div>
//                 <button
//                   className="text-white p-2 w-full rounded-lg md:h-16 text-3xl"
//                   style={{ background: "#393285" }}
//                 >
//                   LogIn
//                 </button>
//               </form> */}
//                 <form
//                   onSubmit={otpSent ? handleVerifyOtp : handleSendOtp}
//                   className="space-y-4"
//                 >
//                   <div className="flex space-x-2">
//                     <input
//                       type="text"
//                       value={countryCode}
//                       onChange={(e) => setCountryCode(e.target.value)}
//                       placeholder="+91"
//                       className="border p-2 rounded-lg w-16 font-semibold md:h-14 text-2xl"
//                     />
//                     <input
//                       type="text"
//                       value={phoneNumber}
//                       onChange={(e) => setPhoneNumber(e.target.value)}
//                       placeholder="Mobile number"
//                       className="border p-2 rounded-lg w-full font-semibold md:h-14 text-2xl"
//                     />
//                   </div>
//                   {otpSent && (
//                     <input
//                       type="text"
//                       value={otp}
//                       onChange={(e) => setOtp(e.target.value)}
//                       placeholder="Enter OTP"
//                       className="border p-2 rounded-lg w-full font-semibold md:h-14 text-2xl"
//                     />
//                   )}
//                   <button
//                     type="submit"
//                     className="text-white p-2 w-full rounded-lg md:h-16 text-3xl"
//                     style={{ background: "#393285" }}
//                   >
//                     {otpSent ? "Verify OTP" : "Send OTP"}
//                   </button>
//                 </form>
//                 <p className="text-center mt-4 font-semibold md:text-xl">
//                   Click here to login using Google/FB account
//                 </p>
//                 <div className="flex justify-center space-x-2 mt-2">
//                   <img
//                     src="/google.png"
//                     alt="Google"
//                     className="w-8"
//                     onClick={handleGoogleSignIn}
//                   />
//                   <img
//                     src="/facebook.png"
//                     alt="Facebook"
//                     className="w-8"
//                     onClick={handleFacebookSignIn}
//                   />
//                 </div>
//               </div>
//             </div>
//           </div>

//           {/* for mobile responsive duplicate added where employeer login section is hidden and it will display  */}
//           <div className="text-xl hidden sm:hidden font-semibold ">
//             <p>Looking for a Job?</p>
//             <button
//               className="text-blue-700"
//               onClick={() => navigate("/Frame-11")}
//             >
//               Click Here
//             </button>
//           </div>

//           {/* Companies that hire from us */}
//           <div className="text-center mb-6 hidden md:block">
//             <h2 className="text-2xl md:text-3xl font-bold text-purple-800">
//               Companies that hire from us
//             </h2>
//           </div>

//           <div className="hidden md:grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-10 items-center justify-center">
//             {companies.map((logo, idx) => (
//               <div
//                 key={idx}
//                 className="w-24 h-24 md:w-32 md:h-32 flex justify-center items-center"
//               >
//                 <img
//                   src={`/companies logo/${logo}`}
//                   alt={logo}
//                   className="object-contain w-full h-full"
//                 />
//               </div>
//             ))}
//           </div>
//         </div>

//         <div className="md:hidden">
//           <HorizontalScroll data="Companies that hire from us" />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Desktop3;


import React, { useEffect, useState } from "react";
import HorizontalScroll from "./Desktop1/companys_scroll";
import { auth, googleProvider } from "./firebase";
import {
  // signInWithRedirect,
  // onAuthStateChanged,
  signInWithPopup,
} from "firebase/auth";

import { useNavigate } from "react-router-dom";
// import HashLoader from "react-spinners/HashLoader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import axios from "axios";

const Desktop3 = () => {
  // const companies = [
  //   "Zomato_logo.png",
  //   "rapido.png",
  //   "paytm.png",
  //   "uber.png",
  //   "samsung.png",
  //   "techmahindra.png",
  //   "swiggy.png",
  //   "daikin.png",
  //   "airtel.jpg",
  //   "webvi.png",
  //   "tp.png",
  //   "policybazaar.jpeg",
  //   "moneylink.png",
  //   "ambar skytech.png",
  // ];
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const type = localStorage.getItem("type");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (token && type === "employee") {
      navigate("/Frame-18");
    }
  }, [type, token, navigate]);

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      // testing code
      const idToke = await user.getIdTokenResult();
      if (!idToke.claims.role) {
        await setRoleOnBackend(user.uid, "employee"); // Set role as "employee" for new users
        await getTokenFromServer(user.uid, "employee"); // Reuse the external function
        toast.success("Google Sign-In successful!");
        navigate("/Frame-18");
      } else {
        const role = idToke.claims.role;
        // console.log("Role detected: ", role);
        if (role === process.env.REACT_APP_TYPE_EMPLOYEE) {
          await getTokenFromServer(
            user.uid,
            process.env.REACT_APP_TYPE_EMPLOYEE
          ); // Reuse the external function
          toast.success("Google Sign-In successful!");
          navigate("/Frame-18");
          // Redirect to admin dashboard
        } else if (
          role === process.env.REACT_APP_TYPE_EMPLOYEE ||
          role === process.env.REACT_APP_TYPE_ADMIN
        ) {
          toast.error("Access Denied for this email");
        } else {
          toast.error("Invalid role detected. Please contact support.", role);
        }
      }

      // Fetch token from server and store in localStorage
    } catch (error) {
      console.error("Error during Google Sign-In: ", error);
      if (!toast.isActive("google-credentials-toast")) {
        toast.error("Google Sign-In failed. Please try again.", {
          toastId: "google-credentials-toast",
        });
      }
    }
  };

  // const handleGoogleSignIn = async () => {
  //   try {
  //     await signInWithRedirect(auth, googleProvider);
  //   } catch (error) {
  //     console.error("Error during Google Sign-In: ", error);
  //     toast.error("Google Sign-In failed. Please try again.");
  //   }
  // };

  // useEffect(() => {
  //   const unsubscribe = onAuthStateChanged(auth, (user) => {
  //     if (user) {
  //       console.log("User detected:", user);
  //       const processUser = async () => {
  //         try {
  //           let idTokenResult = await user.getIdTokenResult();
  //           console.log("ID token result:", idTokenResult);

  //           if (!idTokenResult.claims.role) {
  //             // Assign 'employee' role to new users
  //             await setRoleOnBackend(user.uid, "employee");

  //             // Force refresh the ID token to pick up the new claims
  //             await user.getIdToken(true);

  //             // Retrieve the updated ID token result
  //             idTokenResult = await user.getIdTokenResult();
  //           }

  //           const role = idTokenResult.claims.role;

  //           if (role === process.env.REACT_APP_TYPE_EMPLOYEE) {
  //             await getTokenFromServer(
  //               user.uid,
  //               process.env.REACT_APP_TYPE_EMPLOYEE
  //             );
  //             toast.success("Google Sign-In successful!");
  //             // navigate("/Frame-18");
  //           } else if (role === process.env.REACT_APP_TYPE_ADMIN) {
  //             toast.error("Access Denied for this email");
  //           } else {
  //             toast.error("Invalid role detected. Please contact support.");
  //           }
  //         } catch (error) {
  //           console.error("Error during role processing:", error);
  //           toast.error("An error occurred during sign-in. Please try again.");
  //         }
  //       };

  //       processUser();
  //     }
  //   });

  //   // Cleanup subscription on unmount
  //   return () => unsubscribe();
  // }, [navigate]);

  const getTokenFromServer = async (uid, role) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_PORT}/generateToken`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ uid, type: role }), // Pass UID and role as necessary data
        }
      );

      if (!response.ok) {
        throw new Error("Failed to retrieve token");
      }

      const data = await response.json();
      localStorage.setItem("token", data.token);
    } catch (error) {
      console.error("Error fetching token:", error);
      throw error; // Rethrow to be caught in the outer try-catch
    }
  };

  const setRoleOnBackend = async (uid, role) => {
    try {
      await fetch(`${process.env.REACT_APP_PORT}/setRole`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ uid, role }),
      });
    } catch (error) {
      console.error("Error setting role:", error);
    }
  };

  const [countryCode, setCountryCode] = useState("+91");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);

  const handleVerifyOtp = async (e) => {
    setLoading(true);
    e.preventDefault();

    if (!otp) {
      toast.error("Please enter the OTP");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PORT}/verify-otp`,
        {
          phoneNumber: countryCode + phoneNumber,
          otp: otp,
        }
      );

      if (response.data.message === "OTP verified successfully") {
        const responso = await fetch(
          `${process.env.REACT_APP_PORT}/generateToken`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              uid: response.data.phoneNumber,
              type: "employee",
            }), // Pass UID or any other necessary data
          }
        );
        const data = await responso.json();
        localStorage.setItem("token", data.token);
        toast.success("OTP verified successfully!");
        // const decryptedPhone = decryptPhoneNumber(response.data.phoneNumber);
        // console.log(decryptedPhone);
        localStorage.setItem("type", "employee");
        navigate("/Frame-18");
      } else {
        toast.error("Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      toast.error("There was an error verifying the OTP.");
    } finally {
      setLoading(false);
    }
  };

  const handleSendOtp = async (e) => {
    setLoading(true);
    e.preventDefault();

    if (!phoneNumber || phoneNumber.length !== 10) {
      toast.error("Please enter a valid 10-digit phone number");
      setTimeout(()=> {
        setLoading(false);
      }, 500)
   
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PORT}/send-otp`,
        {
          phoneNumber: countryCode + phoneNumber,
        }
      );

      if (response.data.message === "OTP sent successfully") {
        toast.success("OTP sent successfully!");
        setOtpSent(true);
      } else {
        toast.error("Failed to send OTP.");
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      toast.error("There was an error sending the OTP.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* full page loading effect */}
      {/* {loading ? (
        <HashLoader
          color="#34a5f3"
          size={60} // Adjust the size as needed
          cssOverride={{
            position: "absolute", // Ensure the loader is positioned relative to the viewport
            top: "50%", // Center vertically
            left: "50%", // Center horizontally
            transform: "translate(-50%, -50%)", // Adjust to truly center the loader
          }}
        />
      ) : ( */}
      <section className="py-10 w-full  font-inter">
        {/* ------------ container ---------------- */}

        <div className="xl:w-10/12 xs:w-11/12 xsm:w-[95%] w-[96%] mx-auto  flex flex-col gap-y-4">
          {/* -------- 1st section -------- */}

          <div className="flex xmd:flex-row flex-col  xl:gap-x-8 gap-x-6 gap-y-12 justify-between ">
            {/* ----- left part ---- */}

            <div className="xl:w-[46%] xmd:w-[43%] md:w-[80%] sm:w-[90%] mx-auto flex flex-col gap-y-4 pr-6 xs:pr-0">
              <h1
                className=" lg:text-[40px] text-4xl font-semibold xl:w-[80%] w-[90%] 
                 sm:leading-[56px] leading-[48px] text-blue-200 "
              >
                Powering Your Hiring Success!!
              </h1>
              <p className="text-lg sm:text-xl font-semibold ">
                Log in now and experience the future of hiring with Hirers.
              </p>
            </div>

            {/* ------- right part ----- */}
            <div className="xl:w-[45%] xmd:w-[50%] md:w-[80%] sm:w-[90%]  w-full  mx-auto  bg-light-purple-100 py-4 xl:px-10 sm:px-6 px-4  rounded-[32px] flex flex-col gap-y-6 items-center ">
              <h2 className=" xl:text-xl xs:text-lg font-semibold text-center">
                Employer LogIn/SignUp
              </h2>

              <form
                onSubmit={otpSent ? handleVerifyOtp : handleSendOtp}
                className="flex flex-col gap-y-4"
              >
                <div className="flex xl:gap-x-8 xsm:gap-x-4 gap-x-1 sm:justify-between justify-center items-center sm:text-lg text-base">
                  <input
                    type="text"
                    value={countryCode}
                    onChange={(e) => setCountryCode(e.target.value)}
                    placeholder="+91"
                    className="py-3 px-2 sm:w-[60px] xsm:w-[48px] w-[44px] rounded-lg text-black placeholder-black font-medium"
                  />
                  <input
                    type="text"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    placeholder="Mobile Number"
                    className="py-3 px-4 rounded-lg placeholder-black text-black font-medium"
                  />
                </div>
                {otpSent && (
                  <input
                    type="text"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    placeholder="Enter OTP"
                    className="py-3 px-4 rounded-lg placeholder-black text-black font-medium"
                  />
                )}

                <div className="flex justify-center">
                  <button
                    type="submit"
                    className="xs:w-full w-[80%] bg-blue-200 text-white flex items-center justify-center py-2 rounded-lg xl:text-xl xsm:text-lg text-base transition-all duration-300 hover:bg-blue-300 hover:scale-105 px-4"
                  >
                    {/* btn loading effect */}
                    {loading ? (
                      <p className="loader"></p>
                    ) : otpSent ? (
                      "Verify OTP"
                    ) : (
                      "Login"
                    )}
                  </button>
                </div>
              </form>

              <div className="flex flex-col gap-y-3 items-center">
                <p className=" text-base font-medium text-center">
                  Click here to login using Google/FB account
                </p>
                <div className="flex gap-x-4">
                  <LazyLoadImage
                    src="/google.png"
                    alt="Google"
                    effect="blur"
                    className="sm:w-8 sm:h-8 w-7 h-7 cursor-pointer transition-all duration-300  hover:scale-110"
                    onClick={handleGoogleSignIn}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* ------- 2nd section ------ */}

          <div className=" font-semibold text-base  mt-6 lg:mt-0 mb-3 ">
            <p className="text-black">Looking for a Job?</p>
            <button
              className="text-blue-200 transition-all duration-300 hover:text-blue-300 hover:scale-105"
              onClick={() => navigate("/Frame-11")}
            >
              Click Here
            </button>
          </div>

          {/* ------- 3rd section --- */}
          {/* 
          <div className=" py-2">
            <div className="hidden xmd:flex flex-col gap-y-12">
              <h2 className="text-center font-semibold text-blue-200 text-3xl">
                Companies that hire from us
              </h2>

              <div>
                <HorizontalScroll/>
              </div>


             
            </div>


          </div> */}
        </div>

        <HorizontalScroll data="Company that hire from us" className="w-full" />
      </section>
      {/* )} */}
    </>
  );
};

export default Desktop3;