import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";

// Your Firebase configuration (from Firebase Console)
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Providers for Google and Facebook sign-in
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

// Setting up ReCAPTCHA for phone authentication
const setUpRecaptcha = () => {
  console.log("Setting up ReCAPTCHA...");

  // Ensure the 'recaptcha-container' exists
  if (!document.getElementById("recaptcha-container")) {
    console.error("'recaptcha-container' not found in the DOM!");
    return;
  }

  if (!window.recaptchaVerifier) {
    window.recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // onSignInSubmit();
          console.log("ReCAPTCHA Verified!", response);
        },
      }
    );

    // Render ReCAPTCHA to confirm it's working
    window.recaptchaVerifier.render().then((widgetId) => {
      console.log("ReCAPTCHA Widget ID:", widgetId);
    });
  } else {
    console.log("ReCAPTCHA already initialized.");
  }
};

// Ensure the reCAPTCHA script is loaded (check if grecaptcha is undefined)
if (typeof grecaptcha === "undefined") {
  const script = document.createElement("script");
  script.src = "https://www.google.com/recaptcha/api.js";
  script.async = true;
  script.defer = true;
  document.head.appendChild(script);
}

export {
  auth,
  googleProvider,
  facebookProvider,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  setUpRecaptcha,
};
