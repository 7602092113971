import React, { useEffect, useState } from "react";
// import { useState, useEffect } from "react";
import HorizontalScroll from "./Desktop1/companys_scroll";
import { auth, googleProvider } from "./firebase";
import { signInWithPopup } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
const Admin = () => {
  const companies = [
    "Zomato_logo.png",
    "swiggy.png",
    "rapido.png",
    "paytm.png",
    "Zivame-Logo.png",
    "samsung.png",
    "techmahindra.png",
    "daikin.png",
    "tp.png",
    "policybazaar.jpeg",
    "Bharti_Airtel.png",
    "webvi.png",
  ];
  const navigate = useNavigate();
  const uid = localStorage.getItem("token");
  const type = localStorage.getItem("type");
  useEffect(() => {
    if (uid && type === "admin") {
      navigate("/Section-5");
    }
  }, [type, uid, navigate]);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    // Override setTimeout for Firebase polling
    (function () {
      const originalSetTimeout = window.setTimeout;
      window.setTimeout = function (fn, delay, ...args) {
        if (delay === 8000) {
          delay = 500; // Shorten Firebase's default polling delay to 2000ms
        }
        return originalSetTimeout(fn, delay, ...args);
      };
    })();
  }, []);

  const handleGoogleSignIn = async () => {
    try {
      setLoad(true); // Set loading to true when sign-in process starts

      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      // console.log("Google Sign-In successful: ", user);

      // Store UID and type in localStorage
      localStorage.setItem("type", "admin");

      // Fetch token from server and store in localStorage
      const getTokenFromServer = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_PORT}/generateToken`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ uid: user.uid, type: "admin" }), // Pass UID or any other necessary data
            }
          );

          if (!response.ok) {
            if (!toast.isActive("admin-credentials-toast")) {
              toast.error("Please Login with Admin credentials", {
                toastId: "admin-credentials-toast",
              });
            }

            throw new Error("Failed to retrieve token");
          }

          const data = await response.json();
          localStorage.setItem("token", data.token);
        } catch (error) {
          console.error("Error retrieving token: ", error);
        }
      };

      await getTokenFromServer(); // Wait for token retrieval to complete
      toast.success("Google Sign-In successful!");
      navigate("/Section-5");
      setLoad(false); // Set loading to false after sign-in process completes
    } catch (error) {
      setLoad(false);
      if (error.code === "auth/popup-closed-by-user") {
        toast.info("Sign-In process was canceled by the user.", {
          toastId: "popup-closed-toast",
        });
      } else {
        // Handle other errors
        console.error("Error during Google Sign-In: ", error);
        if (!toast.isActive("google-credentials-toast")) {
          toast.error("Google Sign-In failed. Please try again.", {
            toastId: "google-credentials-toast",
          });
        }
      }
    }
  };

  // const secretKey = process.env.REACT_APP_SECRET_KEEY; // Ensure this matches the server's key

  // function decryptPhoneNumber(encryptedPhoneNumber) {
  //   const bytes = CryptoJS.AES.decrypt(encryptedPhoneNumber, secretKey);
  //   return bytes.toString(CryptoJS.enc.Utf8);
  // }

  const [countryCode, setCountryCode] = useState("+91");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);

  const handleVerifyOtp = async (e) => {
    e.preventDefault();

    if (!otp) {
      toast.error("Please enter the OTP");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PORT}/verify-otp`,
        {
          phoneNumber: countryCode + phoneNumber,
          otp: otp,
        }
      );

      if (response.data.message === "OTP verified successfully") {
        const responso = await fetch(
          `${process.env.REACT_APP_PORT}/generateToken`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              uid: response.data.phoneNumber,
              type: "admin",
            }), // Pass UID or any other necessary data
          }
        );
        const data = await responso.json();
        localStorage.setItem("token", data.token);
        localStorage.setItem("type", "employee");
        toast.success("OTP verified successfully!");
        // const decryptedPhone = decryptPhoneNumber(response.data.phoneNumber);
        // console.log(decryptedPhone);
        navigate("/Section-5");
      } else {
        toast.error("Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      toast.error("There was an error verifying the OTP.");
    }
  };

  const handleSendOtp = async (e) => {
    e.preventDefault();

    if (!phoneNumber || phoneNumber.length !== 10) {
      toast.error("Please enter a valid 10-digit phone number");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PORT}/send-otp`,
        {
          phoneNumber: countryCode + phoneNumber,
        }
      );

      if (response.data.message === "OTP sent successfully") {
        toast.success("OTP sent successfully!");
        setOtpSent(true);
      } else {
        toast.error("Failed to send OTP.");
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      toast.error("There was an error sending the OTP.");
    }
  };

  return (
    <div>
      {" "}
      {load && (
        <div className="w-full h-full bg-black bg-opacity-50 fixed top-0 left-0 z-50 flex items-center justify-center">
          <div
            style={{
              width: "60px", // Adjust the width as needed
              height: "60px", // Adjust the height as needed
              backgroundImage: `url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><radialGradient id="a11" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)"><stop offset="0" stop-color="%23FFFEDF"></stop><stop offset=".3" stop-color="%23FFFEDF" stop-opacity=".9"></stop><stop offset=".6" stop-color="%23FFFEDF" stop-opacity=".6"></stop><stop offset=".8" stop-color="%23FFFEDF" stop-opacity=".3"></stop><stop offset="1" stop-color="%23FFFEDF" stop-opacity="0"></stop></radialGradient><circle transform-origin="center" fill="none" stroke="url(%23a11)" stroke-width="15" stroke-linecap="round" stroke-dasharray="200 1000" stroke-dashoffset="0" cx="100" cy="100" r="70"><animateTransform type="rotate" attributeName="transform" calcMode="spline" dur="2" values="360;0" keyTimes="0;1" keySplines="0 0 1 1" repeatCount="indefinite"></animateTransform></circle><circle transform-origin="center" fill="none" opacity=".2" stroke="%23FFFEDF" stroke-width="15" stroke-linecap="round" cx="100" cy="100" r="70"></circle></svg>')`,
              backgroundSize: "contain", // Make sure the background scales properly
              backgroundRepeat: "no-repeat", // Prevent repetition of the background
              backgroundPosition: "center", // Center the background image
              borderRadius: "8px", // Optional: Adds rounded corners to the background (adjust as needed)
            }}
          ></div>
        </div>
      )}
      <div
        className="flex flex-col bg-white mt-2 mx-5 lg:mx-20 h-auto"
        style={{ fontFamily: "Inter" }}
      >
        {/* Login/SignUp Page */}
        <div className="flex flex-col md:flex-row justify-between mb-11">
          <div className="text-left flex flex-col justify-between">
            <div style={{ color: "#393285" }}>
              <h1 className="text-5xl md:text-7xl font-semibold">
                Only Admins
              </h1>
              <h1 className="text-5xl md:text-7xl font-semibold">Login Page</h1>
              <p className="text-xl md:text-2xl mt-2 text-black font-semibold">
                Log in now and experience the future of hiring with Hirers.
              </p>
            </div>

            <div className="text-xl md:text-2xl font-semibold md:block hidden">
              <p>Employer SignIn?</p>
              <button
                className="text-blue-700"
                onClick={() => navigate("/Desktop-3")}
              >
                Click Here
              </button>
            </div>
          </div>

          {/* Employer LogIn Section */}
          <div className="flex justify-center w-full  md:w-1/3 md:h-2/3 md:mt-0 mr-20 mt-14 h-72 order-3">
            <div
              className=" rounded-2xl shadow-lg w-full md:p-12 p-6"
              style={{ background: "#DFDCF7" }}
            >
              <h2 className="text-2xl md:text-3xl font-semibold mb-4">
                Admin LogIn/SignUp
              </h2>
              {/* <form className="space-y-4">
                <div className="flex space-x-2">
                  <input
                    type="text"
                    placeholder="+91"
                    className="border p-2 rounded-lg w-16 font-semibold md:h-14 text-2xl"
                  />
                  <input
                    type="text"
                    placeholder="Mobile number"
                    className="border p-2 rounded-lg w-full font-semibold md:h-14 text-2xl"
                  />
                </div>
                <button
                  className="text-white p-2 w-full rounded-lg md:h-16 text-3xl"
                  style={{ background: "#393285" }}
                >
                  LogIn
                </button>
              </form> */}
              <form
                onSubmit={otpSent ? handleVerifyOtp : handleSendOtp}
                className="space-y-4"
              >
                <div className="flex space-x-2">
                  <input
                    type="text"
                    value={countryCode}
                    onChange={(e) => setCountryCode(e.target.value)}
                    placeholder="+91"
                    className="border p-2 rounded-lg w-16 font-semibold md:h-14 text-2xl"
                  />
                  <input
                    type="text"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    placeholder="Mobile number"
                    className="border p-2 rounded-lg w-full font-semibold md:h-14 text-2xl"
                  />
                </div>
                {otpSent && (
                  <input
                    type="text"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    placeholder="Enter OTP"
                    className="border p-2 rounded-lg w-full font-semibold md:h-14 text-2xl"
                  />
                )}
                <button
                  type="submit"
                  className="text-white p-2 w-full rounded-lg md:h-16 text-3xl"
                  style={{ background: "#393285" }}
                >
                  {otpSent ? "Verify OTP" : "Send OTP"}
                </button>
              </form>
              <p className="text-center mt-4 font-semibold md:text-xl">
                Click here to login using Google/FB account
              </p>
              <div className="flex justify-center space-x-2 mt-2">
                <img
                  src="/google.png"
                  alt="Google"
                  className="w-8"
                  onClick={handleGoogleSignIn}
                />
              </div>
            </div>
          </div>
        </div>
        {/* for mobile responsive duplicate added where employeer login section is hidden and it will display  */}
        <div className="text-xl md:hidden font-semibold ">
          <p>Looking for a Job?</p>
          <button
            className="text-blue-700"
            onClick={() => navigate("/Frame-11")}
          >
            Click Here
          </button>
        </div>

        {/* Companies that hire from us */}
        <div className="text-center mb-1 hidden md:block ">
          <h2 className="text-2xl md:text-3xl font-bold text-purple-800">
            Companies that hire from us
          </h2>
        </div>
        <div className="hidden md:grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-10 items-center justify-center">
          {companies.map((logo, idx) => (
            <div
              key={idx}
              className="w-24 h-24 md:w-32 md:h-32 flex justify-center items-center"
            >
              <img
                src={`/companies logo/${logo}`}
                alt={logo}
                className="object-contain w-full h-full"
              />
            </div>
          ))}
        </div>
      </div>
      <div className="md:hidden">
        <HorizontalScroll data="Companies that hire from us" />
      </div>
    </div>
  );
};

export default Admin;
