import { React, useState, useEffect } from "react";
// import { FaAngleRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { GiHamburgerMenu } from "react-icons/gi";
import { toast } from "react-toastify";
import axios from "axios";

const Navemployee = () => {
  const navigate = useNavigate();
  const [validUser, setValidUser] = useState(false);


  const t1 = localStorage.getItem("token");

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_PORT}/api/updateprofiledata`,
          {
            type: process.env.REACT_APP_TYPE_EMPLOYER,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${t1}`, // Uncomment if authorization is needed
            },
          }
        );



        // console.log(profileData);
        if (!response.data.message) {
         setValidUser(true);
        }
      } catch (err) {
        console.log("Error in Navbar Employee")
      }
    };

    fetchProfileData(); // Call the function once the token is available
  }, [t1, navigate]);

  const handleProf = () => {
    if (validUser) {
      navigate("/Frame-10");
    } else {
      toast.dismiss();
      if (!toast.isActive("invalid-credentials-toast")) {
        toast.warn("Fill all the details first", {
          toastId: "invalid-credentials-toast",
        });
      }
    }
  };
  const frame11 = () => {
    if (validUser) {
      navigate("/Frame-10");
    } else {
      toast.dismiss();
      if (!toast.isActive("invalid-credentials-toast")) {
        toast.warn("Fill all the details first", {
          toastId: "invalid-credentials-toast",
        });
      }
    }

  };
  const handleProfile = () => {
    if (validUser) {
      navigate("/Section-6");
    } else {
      toast.dismiss();
      if (!toast.isActive("invalid-credentials-toast")) {
        toast.warn("Fill all the details first", {
          toastId: "invalid-credentials-toast",
        });
      }
    }
  };
  const handleDashboard = () => {
    navigate("/");
  };

  return (
    <div className="w-full xs:h-18 h-16  text-white flex justify-between items-center mx-auto bg-primary-blue font-inter ">
      <div className=" h-14 w-14 ml-2 sm:ml-6  rounded-full flex items-center cursor-pointer shadow-xl">
        <img src="/hirersLogo.png" alt="logo" onClick={handleDashboard} />
      </div>

      <div className="flex items-center justify-evenly">
        <span
          className="block sm:hidden md:h-10 md:pr-3 pt-2 cursor-pointer mr-5 text-sm  xsm:text-base hover:scale-105 transition-all duration-200"
          onClick={frame11}
        >
          Search Job
        </span>
        <span className="sm:hidden flex sm:pr-3 pt-1">
          <div
            className="  h-10 w-10  mr-4 rounded-full hover:scale-105 transition-all duration-200"
            onClick={handleProfile}
          >
            <img
              src="/profile-img.webp" // Corrected path to the image in the public folder
              alt="menu icon"
              className="h-full w-full rounded-full"
            />
          </div>
        </span>
        <span
          className="hidden sm:block pr-3 pt-1 cursor-pointer mr-12 xmd:text-lg hover:scale-105 transition-all duration-200"
          onClick={handleProf}
        >
          Search Job
        </span>
        <div className="relative hidden sm:flex items-center bg-white rounded-xl px-4 py-1 mb-2 mt-2 mr-8 cursor-pointer hover:scale-105 transition-all duration-200 hover:bg-gray">
          <p
            className="cursor-pointer text-primary-blue text-base md:text-lg"
            onClick={handleProfile}
          >
            Your Profile
          </p>

          <div className=" h-6 w-6 ml-4  ">
            <GiHamburgerMenu className="text-black h-full w-full" />
          </div>
        </div>
        <div className="sm:mr-4">
          <span
            className="material-symbols-outlined text-[#4285F4]  mr-4 mt-2 hover:scale-105 transition-all duration-200
    cursor-pointer 
    text-[30px] 
    float-right 
    md:text-[40px] 
    sm:text-[30px] 
    sm:float-none 
    sm:block 
    sm:mx-auto"
            onClick={() => {
              Swal.fire({
                title: "Are you sure you want to log out?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, log out",
                cancelButtonText: "Cancel",
              }).then((result) => {
                if (result.isConfirmed) {
                  // Clear local storage items
                  localStorage.removeItem("type");
                  localStorage.removeItem("token");

                  // Show success message
                  Swal.fire({
                    title: "Logged out successfully",
                    icon: "success",
                    timer: 2000,
                    showConfirmButton: false,
                  });

                  // Redirect to home page
                  navigate("/");
                }
              });
            }}
          >
            logout
          </span>
        </div>
      </div>
    </div>
  );
};

export default Navemployee;
